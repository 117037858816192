import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

const TransactionConfig = () => {

  const { currentColor } = useStateContext();

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [warning, setWarning] = useState({
    price_per_balance: null,
    price_pascabayar: null,
  });
  const [disabled, setDisabled] = useState(false);

  const pattern = {
    price_per_balance: /^[0-9]*$/,
    price_pascabayar: /^[0-9]*$/,
    tax: /^[0-9]+(\.[0-9]+)?$/,
    email: /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]+)$/,
    due_date_days: /^[0-9]*$/,
  }

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/masterdata-management/transaction-configs", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setData(response.data.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const updateData = async () => {
    await axios.put(API_ROOT + "/admin/masterdata-management/transaction-configs", {
      price_per_balance: Number(data.price_per_balance),
      price_pascabayar: Number(data.price_pascabayar),
      tax: Number(data.tax),
      email: data.email,
      due_date_days: Number(data.due_date_days),
    }, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      toast.success("Masterdata updated!");
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        } else if (error.response.data.err.type === "service") {
          if (error.response.data.err.data.code === -1) {
            toast.warning("Nothing updated!");
          }
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    for (const value of Object.values(warning)) {
      if (value !== null) {
        setDisabled(true);

        return;
      }
    }
    setDisabled(false);
  }, [warning]);

  return !data ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Transaction Configuration" />
      <div className="flex flex-col gap-4 w-fit">
        <div className="flex flex-col gap-2">
          <h4 className="font-medium">Price per balance (IDR)</h4>
          <input
            type="text"
            spellCheck="false"
            className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!warning.price_per_balance ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
            value={data.price_per_balance}
            onChange={(e) => {
              setData({ ...data, price_per_balance: e.target.value});

              if (e.target.value.length < 1) {
                setWarning((prev) => ({ ...prev, price_per_balance: "Minimum of 1 number is required" }));
              } else if (!pattern.price_per_balance.test(e.target.value)) {
                setWarning((prev) => ({ ...prev, price_per_balance: "Input contain not allowed characters or combinations" }));
              } else {
                setWarning((prev) => ({ ...prev, price_per_balance: null }));
              }
            }}
          />
          {
            warning.price_per_balance && (
              <p className="text-xs text-red-500">{warning.price_per_balance}</p>
            )
          }
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-medium">Price pascabayar (IDR)</h4>
          <input
            type="text"
            spellCheck="false"
            className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!warning.price_pascabayar ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
            value={data.price_pascabayar}
            onChange={(e) => {
              setData({ ...data, price_pascabayar: e.target.value});

              if (e.target.value.length < 1) {
                setWarning((prev) => ({ ...prev, price_pascabayar: "Minimum of 1 number is required" }));
              } else if (!pattern.price_pascabayar.test(e.target.value)) {
                setWarning((prev) => ({ ...prev, price_pascabayar: "Input contain not allowed characters or combinations" }));
              } else {
                setWarning((prev) => ({ ...prev, price_pascabayar: null }));
              }
            }}
          />
          {
            warning.price_pascabayar && (
              <p className="text-xs text-red-500">{warning.price_pascabayar}</p>
            )
          }
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-medium">Tax (VAT) %</h4>
          <input
            type="text"
            spellCheck="false"
            className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!warning.tax ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
            value={data.tax}
            onChange={(e) => {
              setData({ ...data, tax: e.target.value});

              if (e.target.value.length < 1) {
                setWarning((prev) => ({ ...prev, tax: "Minimum of 1 number is required" }));
              } else if (!pattern.tax.test(e.target.value)) {
                setWarning((prev) => ({ ...prev, tax: "Input contain not allowed characters or combinations" }));
              } else {
                setWarning((prev) => ({ ...prev, tax: null }));
              }
            }}
          />
          {
            warning.tax && (
              <p className="text-xs text-red-500">{warning.tax}</p>
            )
          }
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-medium">CS Email</h4>
          <input
            type="text"
            maxLength={50}
            spellCheck="false"
            className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!warning.email ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
            value={data.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value});

              if (e.target.value.length < 1) {
                setWarning((prev) => ({ ...prev, email: "Minimum of 1 character is required" }));
              } else if (!pattern.email.test(e.target.value)) {
                setWarning((prev) => ({ ...prev, email: "Input contain not allowed characters or combinations" }));
              } else {
                setWarning((prev) => ({ ...prev, email: null }));
              }
            }}
          />
          {
            warning.email && (
              <p className="text-xs text-red-500">{warning.email}</p>
            )
          }
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-medium">Invoice due (days)</h4>
          <input
            type="text"
            spellCheck="false"
            className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!warning.due_date_days ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
            value={data.due_date_days}
            onChange={(e) => {
              setData({ ...data, due_date_days: e.target.value});

              if (e.target.value.length < 1) {
                setWarning((prev) => ({ ...prev, due_date_days: "Minimum of 1 number is required" }));
              } else if (!pattern.due_date_days.test(e.target.value)) {
                setWarning((prev) => ({ ...prev, due_date_days: "Input contain not allowed characters or combinations" }));
              } else {
                setWarning((prev) => ({ ...prev, due_date_days: null }));
              }
            }}
          />
          {
            warning.due_date_days && (
              <p className="text-xs text-red-500">{warning.due_date_days}</p>
            )
          }
        </div>
        <div className="flex justify-end">
          <button
            disabled={disabled}
            onClick={updateData}
            style={{
              backgroundColor: currentColor,
            }}
            className={"py-2 px-4 rounded-md text-white font-medium " + (disabled ? "cursor-not-allowed": "")}
          >
            Update data
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransactionConfig;