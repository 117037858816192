import axios from "axios";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { getCookie } from "cookies-next";
import { FaXmark } from "react-icons/fa6";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./integratedPlatform.css";
import { API_ROOT } from "../../../config";
import BlankProfile from "../../../data/blank.png";
import { Header, PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

const IntegratedPlatform = () => {

  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const [refresh, setRefresh] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [platformData, setPlatformData] = useState([]);
  const [toggleAdd, setToggleAdd] = useState(false);
  const [addPlatformData, setAddPlatformData] = useState({
    platform_name: null,
    customer_key: null,
    selected_owner: null,
    owner_temp: null,
    owner_list: [],
		selected_member: [],
		member_temp: null,
		member_list: [],
  });
  const [addPlatformWarning, setAddPlatformWarning] = useState({
    platform_name: null,
    customer_key: null,
    selected_owner: null,
		selected_member: null,
  });
  const addPlatformPattern = {
    customer_key: /^[a-zA-Z0-9_]+$/,
  };
  const [addPlatformDisabled, setAddPlatformDisabled] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const gridRef = useRef(null);

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/list?offset=1&limit=all", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const platformList = response.data.data;

      setPlatformData(() => platformList.map((item, index) => (
        {
          Uuid: item.uuid,
          No: index+1,
          PlatformName: item.platform_name,
          CustomerKey: item.customer_key,
          IsActive: item.is_active ? "True" : "False",
        }
      )));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");

          return;
        }
      }
      toast.error("Failed to get data");
    }).finally(() => setPageLoading(false));
  };

  useEffect(() => {
    setPageLoading(true);
    setPlatformData([]);
    setToggleAdd(false);
    setAddPlatformData({
      platform_name: null,
      customer_key: null,
      selected_owner: null,
      owner_temp: null,
      owner_list: [],
			selected_member: [],
			member_temp: null,
			member_list: [],
    });
    setAddPlatformWarning({
      platform_name: null,
      customer_key: null,
      selected_owner: null,
			selected_member: null,
    });
    setAddLoading(false);
    setAddPlatformDisabled(false);
    fetchData();
  }, [refresh]);

  useEffect(() => {
    for (const value of Object.values(addPlatformWarning)) {
      if (value !== null) {
        setAddPlatformDisabled(true);

        return;
      }
    }
    setAddPlatformDisabled(false);
  }, [addPlatformWarning]);

  useEffect(() => {
    async function searchUser(value) {
      if (value) {
        await axios.get(API_ROOT + "/admin/user/search?keywords=" + value, {
          headers: {
            Authorization: getCookie("admin_auth"),
          },
        }).then((response) => {
          const userList = response.data.data;
    
          setAddPlatformData((prev) => ({ ...prev, owner_list: userList}));

        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              navigate("/login");
    
              return;
            }
          }
          setAddPlatformData((prev) => ({ ...prev, owner_list: []}));
          toast.error("Failed to get data");
        })
      } else {
        setAddPlatformData((prev) => ({ ...prev, owner_list: []}));
      }
    }

    const timeout = setTimeout(() => {
      searchUser(addPlatformData.owner_temp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [addPlatformData.owner_temp]);

	useEffect(() => {
    async function searchUser(value) {
      if (value) {
        await axios.get(API_ROOT + "/admin/user/search?keywords=" + value, {
          headers: {
            Authorization: getCookie("admin_auth"),
          },
        }).then((response) => {
          const userList = response.data.data;
    
          setAddPlatformData((prev) => ({ ...prev, member_list: userList}));

        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              navigate("/login");
    
              return;
            }
          }
          setAddPlatformData((prev) => ({ ...prev, member_list: []}));
          toast.error("Failed to get data");
        })
      } else {
        setAddPlatformData((prev) => ({ ...prev, member_list: []}));
      }
    }

    const timeout = setTimeout(() => {
      searchUser(addPlatformData.member_temp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [addPlatformData.member_temp]);

	const actionTemplate = (props) => {
    return (
      <div className="e-actionParent">
        <Link to={props.Uuid} className="e-action">
          <BiMessageSquareDetail className="e-actionIcon" />
          <div>Detail</div>
        </Link>
      </div>
    );
  };

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  };

  const dataBound = () => {
    if (gridRef.current) {
      gridRef.current.autoFitColumns(["No", "PlatformName", "CustomerKey", "IsActive", "Uuid", "Total"]);
    }
  };

  const toolbarClick = (args) => {
    if (gridRef.current && args.item.id.includes("excelexport")) {
      const excelExportProperties = {
        fileName: "Paperless - Digitalisasi Untuk Negeri Integrated Platform.xlsx"
      };
      gridRef.current.excelExport(excelExportProperties);
    }
  };

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between items-end pb-4">
        <Header title="Integrated Platform Data" />
        <button
          style={{ backgroundColor: currentColor }}
          className="text-white w-36 py-2 px-4 rounded-md text-sm"
          onClick={() => setToggleAdd(true)}
        >
          Add Platform
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="w-fit">
          <GridComponent
            dataSource={platformData}
            width="auto"
            allowPaging
            allowSorting
            allowTextWrap={true}
            pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
            textWrapSettings={{ wrapMode: 'Content' }}
            toolbar={["Search", "ExcelExport"]}
            queryCellInfo={queryCellInfo}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            dataBound={dataBound}
            ref={gridRef}
          >
            <ColumnsDirective>
              <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
              <ColumnDirective field="PlatformName" headerText="Platform Name" textAlign="Left" />
              <ColumnDirective field="CustomerKey" headerText="Customer Key" textAlign="Left" />
              <ColumnDirective field="IsActive" headerText="Is Active" textAlign="Center" />
              <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
              <ColumnDirective field="Blank" headerText="" textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
          </GridComponent>
        </div>
      </div>
      {
        toggleAdd && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full overflow-y-auto py-40 px-[100px] bg-slate-800/50">
            <div className="bg-white flex flex-col gap-2 w-full max-w-[464px] h-fit rounded-xl p-8">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-medium">Add Platform</h1>
                <div
                  className="cursor-pointer p-2"
                  onClick={() => {
                    setToggleAdd(false);
                    setAddPlatformData({
                      platform_name: null,
                      customer_key: null,
                      selected_owner: null,
                      owner_temp: null,
                      owner_list: [],
                      selected_member: [],
                      member_temp: null,
                      member_list: [],
                    });
                    setAddPlatformWarning({
                      platform_name: null,
                      customer_key: null,
                      selected_owner: null,
                      selected_member: null,
                    });
                  }}
                >
                  &times;
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-4 w-fit">
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Platform Name</h4>
                  <input
                    type="text"
                    placeholder="Input platform name..."
                    maxLength={100}
                    spellCheck="false"
                    className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!addPlatformWarning.platform_name ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                    value={addPlatformData.platform_name}
                    onChange={(e) => {
                      setAddPlatformData((prev) => ({ ...prev, platform_name: e.target.value}));

                      if (e.target.value.length < 1) {
                        setAddPlatformWarning((prev) => ({ ...prev, platform_name: "Minimum of 1 character is required" }));
                      } else {
                        setAddPlatformWarning((prev) => ({ ...prev, platform_name: null }));
                      }
                    }}
                  />
                  {
                    addPlatformWarning.platform_name && (
                      <p className="text-xs text-red-500">{addPlatformWarning.platform_name}</p>
                    )
                  }
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Customer Key</h4>
                  <input
                    type="text"
                    placeholder="Input customer key..."
                    maxLength={60}
                    spellCheck="false"
                    className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!addPlatformWarning.customer_key ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                    value={addPlatformData.customer_key}
                    onChange={(e) => {
                      setAddPlatformData((prev) => ({ ...prev, customer_key: e.target.value}));

                      if (e.target.value.length < 1) {
                        setAddPlatformWarning((prev) => ({ ...prev, customer_key: "Minimum of 1 character is required" }));
                      } else if (!addPlatformPattern.customer_key.test(e.target.value)) {
                        setAddPlatformWarning((prev) => ({ ...prev, customer_key: "Input contain not allowed characters or combinations" }));
                      } else {
                        setAddPlatformWarning((prev) => ({ ...prev, customer_key: null }));
                      }
                    }}
                  />
                  {
                    addPlatformWarning.customer_key && (
                      <p className="text-xs text-red-500">{addPlatformWarning.customer_key}</p>
                    )
                  }
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Owner</h4>
                  {
                    addPlatformData.selected_owner ? (
                      <div className="flex justify-between items-center bg-white py-3 px-4 border border-slate-500 rounded-md">
                        <div className="flex gap-2 items-center">
                          <div>
                            <img
                              src={addPlatformData.selected_owner.image ? "data:image/*;base64,"+addPlatformData.selected_owner.image : BlankProfile}
                              alt="User profile image"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                          <div className="fles flex-col justify-center text-sm">
                            <h1>{addPlatformData.selected_owner.name}</h1>
                            <h3 className="text-slate-700 font-semibold">{"@" + addPlatformData.selected_owner.username}</h3>
                          </div>
                        </div>
                        <button
                          className="p-2 cursor-pointer"
                          onClick={() => {
                            setAddPlatformData((prev) => ({ ...prev, selected_owner: null}));
                          }}
                        >
                          <FaXmark className="w-3 h-3 text-slate-800" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Search User"
                          spellCheck="false"
                          className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!addPlatformWarning.selected_owner ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                          value={addPlatformData.owner_temp}
                          onChange={(e) => {
                            setAddPlatformData((prev) => ({ ...prev, owner_temp: e.target.value}));
                            setAddPlatformWarning((prev) => ({ ...prev, selected_owner: null}));
                          }}
                        />
                        {
                          addPlatformData.owner_list.length !== 0 && (
                            <div className="flex flex-col w-[400px] max-h-[192px] border border-slate-500 overflow-y-auto rounded-md cursor-pointer">
                              {
                                addPlatformData.owner_list.map((item, index) => (
                                  <div
                                    key={"owner"+index}
                                    className="py-3 px-4 flex justify-between items-center bg-white hover:bg-slate-200 active:bg-slate-50"
                                    onClick={() => {
                                      setAddPlatformData((prev) => ({ ...prev, selected_owner: item, owner_temp: "", owner_list: []}));
                                      setAddPlatformWarning((prev) => ({ ...prev, selected_owner: null}));
                                    }}
                                  >
                                    <div className="flex gap-2 items-center">
                                      <div>
                                        <img
                                          src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                          alt="User profile image"
                                          className="w-10 h-10 rounded-full object-cover"
                                        />
                                      </div>
                                      <div className="fles flex-col justify-center text-sm">
                                        <h1>{item.name}</h1>
                                        <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                      </div>
                                    </div>
                                    <div>
                                      <FaPlus className="w-3 h-3 text-slate-800" />
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    addPlatformWarning.selected_owner && (
                      <p className="text-xs text-red-500">{addPlatformWarning.selected_owner}</p>
                    )
                  }
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Members</h4>
                    {
                      addPlatformData.selected_member.length > 0 && (
                        <div className="flex flex-col bg-white border border-slate-500 rounded-md">
                          {
                            addPlatformData.selected_member.map((item, index) => (
                              <div key={"selectedMember"+index} className="flex justify-between items-center py-3 px-4">
                                <div className="flex gap-2 items-center">
                                  <div>
                                    <img
                                      src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                      alt="User profile image"
                                      className="w-10 h-10 rounded-full object-cover"
                                    />
                                  </div>
                                  <div className="fles flex-col justify-center text-sm">
                                    <h1>{item.name}</h1>
                                    <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                  </div>
                                </div>
                                <button
                                  className="p-2 cursor-pointer"
                                  onClick={() => {
                                    setAddPlatformData((prev) => {
                                      const newSelectedMembers = [...prev.selected_member];
                                      newSelectedMembers.splice(index, 1);

                                      return { ...prev, selected_member: newSelectedMembers };
                                    });
                                  }}
                                >
                                  <FaXmark className="w-3 h-3 text-slate-800" />
                                </button>
                              </div>
                            ))
                          }
                        </div>
                      )	
                    }
                    <input
                      type="text"
                      placeholder="Search User"
                      spellCheck="false"
                      className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!addPlatformWarning.selected_member ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                      value={addPlatformData.member_temp}
                      onChange={(e) => {
                        setAddPlatformData((prev) => ({ ...prev, member_temp: e.target.value }));
                        setAddPlatformWarning((prev) => ({ ...prev, selected_member: null }));
                      }}
                    />
                    {
                      addPlatformData.member_list.length !== 0 && (
                        <div className="flex flex-col w-[400px] max-h-[192px] border border-slate-500 overflow-y-auto rounded-md cursor-pointer">
                          {
                            addPlatformData.member_list.map((item, index) => (
                              <div
                                key={"member"+index}
                                className="py-3 px-4 flex justify-between items-center bg-white hover:bg-slate-200 active:bg-slate-50"
                                onClick={() => {
                                  if (!addPlatformData.selected_member.some((member) => member.uuid === item.uuid)) {
                                    setAddPlatformData((prev) => ({ ...prev, selected_member: [...prev.selected_member, item], member_temp: "", member_list: [] }));
                                    setAddPlatformWarning((prev) => ({ ...prev, selected_member: null }));
                                  }
                                }}
                              >
                                <div className="flex gap-2 items-center">
                                  <div>
                                    <img
                                      src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                      alt="User profile image"
                                      className="w-10 h-10 rounded-full object-cover"
                                    />
                                  </div>
                                  <div className="fles flex-col justify-center text-sm">
                                    <h1>{item.name}</h1>
                                    <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                  </div>
                                </div>
                                <div>
                                  <FaPlus className="w-3 h-3 text-slate-800" />
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                    {
                      addPlatformWarning.selected_member && (
                        <p className="text-xs text-red-500">{addPlatformWarning.selected_member}</p>
                      )
                    }
                </div>
                <div className="flex justify-end items-center">
                  <button
                    disabled={addPlatformDisabled}
                    style={{ backgroundColor: currentColor }}
                    className={"text-white w-36 mt-4 py-2 px-4 rounded-md text-sm " + (addPlatformDisabled ? "cursor-not-allowed" : "cursor-pointer")}
                    onClick={async () => {
                      setAddLoading(true);

                      if (!addPlatformData.platform_name) {
                        setAddPlatformWarning((prev) => ({ ...prev, platform_name: "Platform name can't be empty"}));
                        setAddLoading(false);

                        return;
                      }

                      if (!addPlatformData.customer_key) {
                        setAddPlatformWarning((prev) => ({ ...prev, customer_key: "Customer key can't be empty"}));
                        setAddLoading(false);

                        return;
                      }

                      if (!addPlatformData.selected_owner) {
                        setAddPlatformWarning((prev) => ({ ...prev, selected_owner: "Owner can't be empty"}));
                        setAddLoading(false);

                        return;
                      }

                      // minimum 1 member
                      // if (addPlatformData.selected_member.length < 1) {
                      //   setAddPlatformWarning((prev) => ({ ...prev, selected_member: "Member can't be empty"}));
                      //   setAddLoading(false);

                      //   return;
                      // }

                      await axios.post(API_ROOT + "/admin/user/integrated-platform/manage/add",{
                        platform_name: addPlatformData.platform_name,
                        customer_key: addPlatformData.customer_key,
                        user_uuid: addPlatformData.selected_owner.uuid,
                        members: addPlatformData.selected_member.map((item) => item.uuid),
                      },{
                        headers: {
                          Authorization: getCookie("admin_auth"),
                        },
                      }).then(async () => {
                        setToggleAdd(false);
                        toast.success("Platform Added!", {
                          autoClose: 500,
                          onClose: () => setRefresh((prev) => prev + 1),
                        });
                      }).catch((error) => {

                        toast.error("Failed to add platform!");
                        
                        if (error.response) {
                          if (error.response.status == 401) {
                            navigate("/login");
                          }
                        }
                      });

                      setAddLoading(false);
                    }}
                  >
                    {!addLoading ? "Add Platform" : "Loading..."}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default IntegratedPlatform;