import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./recapPascabayar.css";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";

const RecapPascabayar = () => {

  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(true);
  const [counterData, setCounterData] = useState([]);
  const gridCounterRef = useRef(null);

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/recap/pascabayar?offset=1&limit=all", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const counterList = response.data.data;

      setCounterData(() => counterList.map((item, index) => (
        {
          Uuid: item.uuid,
          No: index+1,
          PlatformName: item.platform_name,
          Total: item.counter,
        }
      )));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");

          return;
        }
      }
      toast.error("Failed to get data");
    }).finally(() => setPageLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionTemplateCounter = (props) => {
    return (
      <div className="e-actionParent">
        <Link to={props.Uuid} className="e-action">
          <BiMessageSquareDetail className="e-actionIcon" />
          <div>Detail</div>
        </Link>
      </div>
    );
  };

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  };

  const dataBoundCounter = () => {
    if (gridCounterRef.current) {
      gridCounterRef.current.autoFitColumns(["No", "PlatformName", "Total", "Uuid"]);
    }
  };

  const toolbarClickCounter = (args) => {
    if (gridCounterRef.current && args.item.id.includes("excelexport")) {
      const excelExportProperties = {
        fileName: "Paperless - Digitalisasi Untuk Negeri Integrated Platform Pascabayar Counter.xlsx"
      };
      gridCounterRef.current.excelExport(excelExportProperties);
    }
  };

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Recap Pascabayar Counter Data" />
      <div className="overflow-x-auto">
        <div className="w-fit">
          <GridComponent
            dataSource={counterData}
            width="auto"
            allowPaging
            allowSorting
            allowTextWrap={true}
            pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
            textWrapSettings={{ wrapMode: 'Content' }}
            toolbar={["Search", "ExcelExport"]}
            queryCellInfo={queryCellInfo}
            allowExcelExport={true}
            toolbarClick={toolbarClickCounter}
            dataBound={dataBoundCounter}
            ref={gridCounterRef}
          >
            <ColumnsDirective>
              <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
              <ColumnDirective field="PlatformName" headerText="Platform Name" textAlign="Left" />
              <ColumnDirective field="Total" headerText="Total" textAlign="Center" />
              <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplateCounter} />
              <ColumnDirective field="Blank" headerText="" textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default RecapPascabayar;