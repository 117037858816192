const convertToTimestamp = (dateString, hours, minutes, seconds) => {
  const [year, month, day] = dateString.split('-');

  const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

  const timestamp = dateObject.getTime();
  
  return timestamp;
}

export default convertToTimestamp;