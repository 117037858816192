import dateFormatter from "./dateFormatter";

const getFirstOfMonth = () => {
  const currentDate = new Date();

  currentDate.setDate(1);
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const timestamp = currentDate.getTime();

  return {
    date: dateFormatter(currentDate),
    timestamp: timestamp,
  };
}

export default getFirstOfMonth;