import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useNavigate } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { useState, useEffect, useRef } from "react";
import { IoCloudUploadOutline } from 'react-icons/io5';
import { BiMessageSquareDetail } from "react-icons/bi";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./pascabayarTransaction.css";
import PutInvoice from "./PutInvoice";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

import getBase64 from "../../../handlers/getBase64";
import formatDate from "../../../handlers/formatDate";

const PascabayarTransaction = () => {

  const { currentColor } = useStateContext();

  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("pending");
  const [transactionList, setTransactionList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [paidList, setPaidList] = useState([]);
  const [doneList, setDoneList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [expiredList, setExpiredList] = useState([]);
  const [toggleDetail, setToggleDetail] = useState(false);
  const [detailInvoice, setDetailInvoice] = useState(null);
  const [rejectedReason, setRejectedReason] = useState("");
  const [toggleReject, setToggleReject] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const gridPendingRef = useRef(null);
  const gridPaidRef = useRef(null);
  const gridDoneRef = useRef(null);
  const gridRejectedRef = useRef(null);
  const gridExpiredRef = useRef(null);

  const [image, setImage] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const inputFileRef = useRef(null);

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/list?offset=1&limit=all&status=all", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const data = response.data.data;

      if (data.length == 0) {
        setPageLoading(false);
      }

      setTransactionList(() => data.map((item) => (
        {
          Uuid: item.uuid,
          NoInvoice: item.no_invoice,
          Invoice: item.uuid,
          PlatformName: item.integrated_platform.platform_name,
          Amount: item.amount,
          Discount: item.discount + "%",
          OriginalPrice: item.original_price,
          Price: item.total_price,
          Status: item.status,
          OrderedDate: formatDate(item.created_at),
          PaidDate: item.paid_at ? formatDate(item.paid_at) : null,
          UpdatedDate: item.updated_at ? formatDate(item.updated_at) : null,
          Description: item.description.length > 30 ? item.description.slice(0, 27) + "..." : item.description,
        }
      )));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const fetchDetail = async (uuid) => {
    setToggleDetail(true);

    await axios.get(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/detail/" + uuid, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const mainData = response.data.data;

      setDetailInvoice(mainData);
      window.scrollTo({ top: 0, left: 0});
    }).catch((error) => {

      setToggleDetail(false);

      toast.error("Failed to open data");
      
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const getFile = async (uploadedFile) => {

    if (uploadedFile.type !== "image/jpeg" && uploadedFile.type !== "image/jpg" && uploadedFile.type !== "image/png") {
      toast.warning("File format must be JPEG/JPG/PNG");
      
      return;
    }

    if (uploadedFile.size > 1048576) {
      toast.warning("Max file size is 1MB");
      
      return;
    }

    const base64Image = await getBase64(uploadedFile);

    setImage(base64Image);

    return;
  }

  const uploadFile = async (uuid) => {
    setUploadLoading(true);

    const paidProof = image.split(",")[1];

    await axios.post(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/paid-proof/" + uuid, {
      paidProof: paidProof
    }, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then(async () => {
      toast.success("Success to upload proof");

      setRefresh((prev) => prev + 1);
    }).catch((error) => {

      toast.error("Failed to create invoice!");
      
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    setUploadLoading(false);
  }

  const getInvoiceMasterdata = async () => {

    let masterdata = {
      bank_payment: null,
      cs_email: null,
    }

    await axios.get(API_ROOT + "/primary/masterdata/bank-payment", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      masterdata.bank_payment = response.data.data;
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    await axios.get(API_ROOT + "/primary/masterdata/cs-email", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      masterdata.cs_email = response.data.data.email;
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    return masterdata;
  }

  const putPdfInvoice = async (detail, status) => {
    const { bank_payment, cs_email } = await getInvoiceMasterdata();

    const userCounterData = detailInvoice.user_document_counter.map(user => ({
      name: user.name,
      quantity: user.count,
    }));

    const InvoicePdf = await PutInvoice(detail, cs_email, bank_payment, status, userCounterData);

    const pdfString = renderToString(InvoicePdf);

    await axios.put(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/invoice/" + detail.uuid, {
      html: pdfString,
    },{
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      if (status == "done") {
        toast.success("Invoice Approved!", {
          autoClose: 500,
          onClose: () => setRefresh((prev) => prev + 1),
        });
      } else if (status == "reject") {
        toast.success("Invoice Rejected!", {
          autoClose: 500,
          onClose: () => setRefresh((prev) => prev + 1),
        });
      }
      window.open(response.data.data.invoice);
    }).catch((error) => {
      toast.error("Failed to Update Invoice");

      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  useEffect(() => {
    setPageLoading(true);
    setTransactionList([]);
    setToggleDetail(false);
    setDetailInvoice(null);
    setImage(null);
    setRejectedReason("");
    setToggleReject(false);
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (transactionList.length !== 0) {
      setPageLoading(false);
      setPendingList(() => transactionList.filter((item) => item.Status === "pending").map((item, index) => ({ ...item, No: index+1})));
      setPaidList(() => transactionList.filter((item) => item.Status === "paid").map((item, index) => ({ ...item, No: index+1})));
      setDoneList(() => transactionList.filter((item) => item.Status === "done").map((item, index) => ({ ...item, No: index+1})));
      setRejectedList(() => transactionList.filter((item) => item.Status === "rejected").map((item, index) => ({ ...item, No: index+1})));
      setExpiredList(() => transactionList.filter((item) => item.Status === "expired").map((item, index) => ({ ...item, No: index+1})));
    }
  }, [transactionList]);

  const actionTemplate = (props) => {
    return (
      <div className="e-actionParent">
        <button onClick={() => fetchDetail(props.Uuid)} className="e-action">
          <BiMessageSquareDetail className="e-actionIcon" />
          <div>Detail</div>
        </button>
      </div>
    );
  };

  const originalPriceTemplate = (props) => {
    return (<>{"Rp " + Number(props.OriginalPrice).toLocaleString("id-ID")}</>);
  }

  const discountPriceTemplate = (props) => {
    return (<>{"Rp " + props.Price.toLocaleString("id-ID")}</>);
  }

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  }

  const dataBound = () => {
    if (gridPendingRef.current) {
      gridPendingRef.current.autoFitColumns(["NoInvoice", "Uuid", "PlatformName", "OrderedDate", "DueDate", "Description"]);
    }
    if (gridPaidRef.current) {
      gridPaidRef.current.autoFitColumns(["NoInvoice", "Uuid", "PlatformName", "OrderedDate", "PaidDate", "Description"]);
    }
    if (gridDoneRef.current) {
      gridDoneRef.current.autoFitColumns(["NoInvoice", "Uuid", "PlatformName", "OrderedDate", "UpdatedDate", "Description"]);
    }
    if (gridRejectedRef.current) {
      gridRejectedRef.current.autoFitColumns(["NoInvoice", "Uuid", "PlatformName", "OrderedDate", "UpdatedDate", "Description"]);
    }
    if (gridExpiredRef.current) {
      gridExpiredRef.current.autoFitColumns(["NoInvoice", "Uuid", "PlatformName", "OrderedDate", "DueDate", "Description"]);
    }
  }

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Pascabayar Transaction Data" sub="Click data to give response" />
      <div
        className="flex items-center flex-wrap gap-8 mb-4 pb-4 border-b-2"
        style={{ 
          borderColor: currentColor,
          }}
      >
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "pending" ? currentColor : "white",
            color: status === "pending" ? "white" : currentColor,
            }}
          onClick={() => setStatus("pending")}
        >
          Unpaid
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "paid" ? currentColor : "white",
            color: status === "paid" ? "white" : currentColor,
            }}
          onClick={() => setStatus("paid")}
        >
          Paid
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "done" ? currentColor : "white",
            color: status === "done" ? "white" : currentColor,
            }}
          onClick={() => setStatus("done")}
        >
          Approved
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "rejected" ? currentColor : "white",
            color: status === "rejected" ? "white" : currentColor,
            }}
          onClick={() => setStatus("rejected")}
        >
          Rejected
        </button>
        {/* <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "expired" ? currentColor : "white",
            color: status === "expired" ? "white" : currentColor,
            }}
          onClick={() => setStatus("expired")}
        >
          Expired
        </button> */}
      </div>
      <div className="overflow-x-auto">
        <div className="w-fit cursor-pointer">
          {
            status === "pending" && (
              <GridComponent
                dataSource={pendingList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search"]}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                dataBound={dataBound}
                ref={gridPendingRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                  <ColumnDirective field="PlatformName" headerText="Platform" textAlign="Left" />
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Discount" headerText="Discount" textAlign="Center" width={90} />
                  <ColumnDirective field="OriginalPrice" headerText="Harga Awal" textAlign="Left" width={120} template={originalPriceTemplate} />
                  <ColumnDirective field="Price" headerText="Harga Diskon" textAlign="Left" width={120} template={discountPriceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" allowSorting={false} />
                  {/* <ColumnDirective field="DueDate" headerText="Due Date" textAlign="Center" /> */}
                  <ColumnDirective field="Description" headerText="Description" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
              </GridComponent>
            )
          }
          {
            status === "paid" && (
              <GridComponent
                dataSource={paidList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search"]}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                dataBound={dataBound}
                ref={gridPaidRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                  <ColumnDirective field="PlatformName" headerText="Platform" textAlign="Left" />
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Discount" headerText="Discount" textAlign="Center" width={90} />
                  <ColumnDirective field="OriginalPrice" headerText="Harga Awal" textAlign="Left" width={120} template={originalPriceTemplate} />
                  <ColumnDirective field="Price" headerText="Harga Diskon" textAlign="Left" width={120} template={discountPriceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="PaidDate" headerText="Paid Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="Description" headerText="Description" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
              </GridComponent>
            )
          }
          {
            status === "done" && (
              <GridComponent
                dataSource={doneList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search"]}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                dataBound={dataBound}
                ref={gridDoneRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                  <ColumnDirective field="PlatformName" headerText="Platform" textAlign="Left" />
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Discount" headerText="Discount" textAlign="Center" width={90} />
                  <ColumnDirective field="OriginalPrice" headerText="Harga Awal" textAlign="Left" width={120} template={originalPriceTemplate} />
                  <ColumnDirective field="Price" headerText="Harga Diskon" textAlign="Left" width={120} template={discountPriceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="UpdatedDate" headerText="Approved Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="Description" headerText="Description" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
              </GridComponent>
            )
          }
          {
            status === "rejected" && (
              <GridComponent
                dataSource={rejectedList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search"]}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                dataBound={dataBound}
                ref={gridRejectedRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                  <ColumnDirective field="PlatformName" headerText="Platform" textAlign="Left" />
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Discount" headerText="Discount" textAlign="Center" width={90} />
                  <ColumnDirective field="OriginalPrice" headerText="Harga Awal" textAlign="Left" width={120} template={originalPriceTemplate} />
                  <ColumnDirective field="Price" headerText="Harga Diskon" textAlign="Left" width={120} template={discountPriceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="UpdatedDate" headerText="Rejected Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="Description" headerText="Description" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
              </GridComponent>
            )
          }
          {
            status === "expired" && (
              <GridComponent
                dataSource={expiredList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search"]}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                dataBound={dataBound}
                ref={gridExpiredRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                  <ColumnDirective field="PlatformName" headerText="Platform" textAlign="Left" />
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Discount" headerText="Discount" textAlign="Center" width={90} />
                  <ColumnDirective field="OriginalPrice" headerText="Harga Awal" textAlign="Left" width={120} template={originalPriceTemplate} />
                  <ColumnDirective field="Price" headerText="Harga Diskon" textAlign="Left" width={120} template={discountPriceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="DueDate" headerText="Due Date" textAlign="Center" allowSorting={false} />
                  <ColumnDirective field="Description" headerText="Description" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
              </GridComponent>
            )
          }
        </div>
      </div>
      {
        toggleDetail && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full py-40 px-[100px] bg-slate-800/50">
            {
              !detailInvoice ? (
                <div className="bg-white w-full max-w-[1000px] h-fit rounded-xl p-8">
                  Loading...
                </div>
              ) : (
                <div className="bg-white w-full max-w-[1000px] h-fit rounded-xl p-8">
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl font-medium">Invoice Detail</h1>
                    <div
                      className="cursor-pointer p-2"
                      onClick={() => {
                        setToggleDetail(false);
                        setDetailInvoice(null);
                        setRejectedReason("");
                        setToggleReject(false);
                      }}
                    >
                      &times;
                    </div>
                  </div>
                  <hr />
                  <div className="flex flex-col gap-1 mt-2">
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">No Invoice</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.no_invoice}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Status</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.status === "pending" ? "unpaid" : detailInvoice.status === "done" ? "approved" : detailInvoice.status}</div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Platform</div>
                      <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.integrated_platform.platform_name}</div>
                    </div>
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Amount</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.amount}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Original Price</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{"Rp " + Number(detailInvoice.original_price).toLocaleString("id-ID")}</div>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Discount</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.discount ? detailInvoice.discount+"%" : "-"}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Final Price</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{"Rp " + detailInvoice.total_price.toLocaleString("id-ID")}</div>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Ordered Date</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{formatDate(detailInvoice.created_at)}</div>
                      </div>
                      {
                        (detailInvoice.status === "pending" || detailInvoice.status === "expired") && (
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Due Date</div>
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.due_date ? formatDate(detailInvoice.due_date) : ""}</div>
                          </div>
                        )
                      }
                      {
                        (detailInvoice.status === "paid" || detailInvoice.status === "done"  || detailInvoice.status === "rejected") && (
                          <div className="basis-1/2 flex">
                            {
                              detailInvoice.status === "paid" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Paid Date</div>
                              )
                            }
                            {
                              detailInvoice.status === "done" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Approved Date</div>
                              )
                            }
                            {
                              detailInvoice.status === "rejected" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Rejected Date</div>
                              )
                            }
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{formatDate(detailInvoice.updated_at)}</div>
                          </div>
                        )
                      }
                    </div>
                    <div className="flex">
                      <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Description</div>
                      <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.description}</div>
                    </div>
                    <div className="flex">
                      <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Invoice</div>
                      <div className="flex-1 px-2 py-2 border border-gray-600 box-border break-all">
                        <button 
                          className="block w-fit text-blue-500 border border-blue-500 py-1 px-2 text-sm"
                          onClick={() => window.open(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/invoice/" + detailInvoice.uuid + "?token=" + getCookie("admin_auth"))}
                        >
                          Open Invoice
                        </button>
                      </div>
                    </div>
                    {
                      (detailInvoice.paid_proof && detailInvoice.paid_at) && (
                        <div className="flex gap-1">
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Paid Proof</div>
                            <div className="flex-1 px-2 py-2 border border-gray-600 box-border break-all">
                              <button 
                                className="block w-fit text-blue-500 border border-blue-500 py-1 px-2 text-sm"
                                onClick={() => {
                                  var image = new Image();
                                  image.src = "data:image/*;base64," + detailInvoice.paid_proof;
                                  image.style.height = "100%";
                                  image.style.width = "auto";

                                  var win = window.open("");
                                  win.document.write(image.outerHTML);
                                }}
                              >
                                Open Image
                              </button>
                            </div>
                          </div>
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Payment Date</div>
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all flex items-center">{formatDate(detailInvoice.paid_at)}</div>
                          </div>
                        </div>
                      )
                    }
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Username</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.integrated_platform.user.username}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Phone Number</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.integrated_platform.user.phone_number}</div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Email</div>
                      <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailInvoice.integrated_platform.user.email}</div>
                    </div>
                  </div>
                  {
                    (detailInvoice.status === "pending" || detailInvoice.status === "rejected") && (
                      <>
                        <div
                          className={"p-4 mt-4 flex flex-col gap-4 justify-center items-center border-2 border-sky-500 border-dashed rounded-xl cursor-pointer " + (!image ? "h-[200px]" : "h-fit")}
                          onClick={() => {
                            if (inputFileRef.current === null) return;

                            inputFileRef.current.click();
                          }}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();

                            if (e.dataTransfer.files.length === 0) return;

                            const file = e.dataTransfer.files[0];
                            getFile(file);
                          }}
                        >
                        {
                          !image ? (
                            <>
                              <IoCloudUploadOutline className="w-12 h-12 text-sky-500" />
                              <div className="text-center">
                                <h1 className="text-lg">Upload Paid Proof</h1>
                                <h2>Drag your image here or click to browse</h2>
                              </div>
                              <h4 className="text-sm text-gray-400">JPEG/JPG/PNG Up to 1MB</h4>
                              <input 
                                type="file"
                                accept="image/jpeg,image/jpg,image/png"
                                className="hidden"
                                ref={inputFileRef}
                                onChange={async(e) => {
                                  if (e.target.files.length === 0) return;
                    
                                  const file = e.target.files[0];
                                  e.target.value = null;
                    
                                  getFile(file);
                                }}
                              />
                            </>
                          ) : (
                            <img 
                              src={image} 
                              alt="Uploaded Payment Proof"
                              className="w-full h-auto rounded-md max-w-[400px]"
                            />
                          )
                        }
                        </div>
                        {
                          image && (
                            <div className="flex justify-end gap-4 mt-4">
                              <button
                                className="bg-gray-400 text-white w-36 py-2 px-4 rounded-md text-sm"
                                disabled={uploadLoading}
                                onClick={() => setImage(null)}
                              >
                                Change Image
                              </button>
                              <button
                                style={{ backgroundColor: currentColor }}
                                className="text-white w-36 py-2 px-4 rounded-md text-sm"
                                disabled={uploadLoading}
                                onClick={() => uploadFile(detailInvoice.uuid)}
                              >
                                {!uploadLoading ? "Submit Proof" : "Loading..."}
                              </button>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  
                  {
                    (detailInvoice.status === "paid" && !toggleReject) && (
                      <div className="flex justify-center items-center gap-4 mt-4">
                        <button
                          disabled={submitLoading}
                          className="text-white w-32 py-2 px-4 rounded-md text-sm bg-green-500 hover:bg-green-600"
                          onClick={async () => {

                            setSubmitLoading(true);

                            await axios.put(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/accept/" + detailInvoice.uuid, {},{
                              headers: {
                                Authorization: getCookie("admin_auth"),
                              },
                            }).then(async () => {
                              await putPdfInvoice(detailInvoice, "done");
                              setToggleDetail(false);
                              setDetailInvoice(null);
                            }).catch((error) => {

                              toast.error("Failed to approve invoice");
                              
                              if (error.response) {
                                if (error.response.status == 401) {
                                  navigate("/login");
                                }
                              }
                            });

                            setSubmitLoading(false);
                          }}
                        >
                          {!submitLoading ? "Approve" : "Loading..."}
                        </button>
                        <button
                          disabled={submitLoading}
                          className="text-white w-32 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                          onClick={async () => {
                            setSubmitLoading(true);

                            await axios.put(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/reject/" + detailInvoice.uuid, {},{
                              headers: {
                                Authorization: getCookie("admin_auth"),
                              },
                            }).then(async () => {
                              await putPdfInvoice(detailInvoice, "rejected");
                              setDetailInvoice(null);
                              setToggleDetail(false);
                            }).catch((error) => {
    
                              toast.error("Failed to reject invoice");
                              
                              if (error.response) {
                                if (error.response.status == 401) {
                                  navigate("/login");
                                }
                              }
                            });

                            setSubmitLoading(false);
                          }}
                        >
                          {!submitLoading ? "Reject" : "Loading..."}
                        </button>
                        {/* <button
                          disabled={submitLoading}
                          className="text-white w-32 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                          onClick={() => setToggleReject(true)}
                        >
                          Reject
                        </button> */}
                      </div>
                    )
                  }
                  {/* {
                    (detailInvoice.status === "rejected" || toggleReject) && (
                      <div className="flex flex-col gap-2 mt-4">
                        <h1>Rejected Reason</h1>
                        <textarea  
                          rows="5"
                          placeholder="rejected reason..."
                          className="w-full border border-gray-400 px-4 py-2 text-sm rounded-md"
                          readOnly={detailInvoice.status === "rejected" ? true : false}
                          value={detailInvoice.status === "rejected" ? detailInvoice.rejected_reason : rejectedReason}
                          onChange={(e) => setRejectedReason(e.target.value)}
                        ></textarea>
                        {
                          detailInvoice.status !== "rejected" && (
                            <div className="flex justify-center items-center gap-4 mt-4">
                              <button
                                disabled={submitLoading}
                                className="text-white w-32 py-2 px-4 rounded-md text-sm bg-slate-500 hover:bg-slate-600"
                                onClick={() => {
                                  setToggleReject(false);
                                  setRejectedReason("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={submitLoading || !rejectedReason}
                                className="text-white w-32 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                                onClick={async () => {

                                  if (!rejectedReason) return;

                                  setSubmitLoading(true);

                                  await axios.put(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/reject/" + detailInvoice.uuid, {
                                    reason: rejectedReason
                                  },{
                                    headers: {
                                      Authorization: getCookie("admin_auth"),
                                    },
                                  }).then(async () => {
                                    await putPdfInvoice(detailInvoice, "rejected");
                                    setDetailInvoice(null);
                                    setToggleDetail(false);
                                    toast.success("Invoice Rejected!", {
                                      autoClose: 500,
                                      onClose: () => setRefresh((prev) => prev + 1),
                                    });
                                  }).catch((error) => {
          
                                    toast.error("Failed to reject invoice");
                                    
                                    if (error.response) {
                                      if (error.response.status == 401) {
                                        navigate("/login");
                                      }
                                    }
                                  });

                                  setSubmitLoading(false);
                                }}
                              >
                                {!submitLoading ? "Reject" : "Loading..."}
                              </button>
                            </div>
                          )
                        }
                      </div>
                    )
                  } */}
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};
export default PascabayarTransaction;