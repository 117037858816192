import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import { API_ROOT } from "../../../config";
import { Header } from "../../../components";

import formatDate from "../../../handlers/formatDate";
import base64UrlSafe from "../../../handlers/base64UrlSafe";

const columns = [
  { field: "id", headerName: "ID", resizable: false },
  { field: "uuid", headerName: "Uuid", resizable: false },
  { field: "user_type", headerName: "User Type", resizable: false },
  { field: "event", headerName: "Event", resizable: false },
  { field: "auditable_type", headerName: "Auditable Type", resizable: false },
  { field: "auditable_id", headerName: "Auditable Id", resizable: false },
  { field: "old_values", headerName: "Old Values", minWidth: 200 },
  { field: "new_values", headerName: "New Values", minWidth: 200 },
  { field: "url", headerName: "Url", resizable: false },
  { field: "ip_adress", headerName: "Ip Address", resizable: false },
  { field: "user_agent", headerName: "User Agent", resizable: false },
  { field: "tags", headerName: "Tags", resizable: false },
  { field: "role", headerName: "Role", resizable: false },
  { field: "created_at", headerName: "Created At", resizable: false },
  { field: "updated_at", headerName: "Updated At", resizable: false },
];

const AuditTrail = () => {

  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    search: "",
    sortModel: [],
  });

  const fetchData = async () => {
    setPageState(prev => ({ ...prev, isLoading: true }));

    await axios.get(API_ROOT + `/admin/user/audit-trail/list?offset=${pageState.page}&limit=${pageState.pageSize}&search=${pageState.search}${pageState.sortModel.length != 0 ? `&sort=${base64UrlSafe(JSON.stringify(pageState.sortModel))}` : ''}&event=all`, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setPageState(prev => ({
        ...prev,
        isLoading: false,
        data: response.data.data.data.map(item => ({
          ...item,
          created_at: formatDate(item.created_at),
          updated_at: formatDate(item.updated_at),
        })),
        total: response.data.data.metadata.total_data
      }));
    }).then(() => setTimeout(0))
    .then(() => {
      apiRef.current.autosizeColumns({
        columns: ["id", "uuid", "user_type", "event", "auditable_type", "auditable_id", "url", "ip_adress", "user_agent", "tags", "role", "created_at", "updated_at"],
        includeHeaders: true,
        includeOutliers: true,
      });
    }).catch((error) => {
      setPageState(prev => ({ ...prev, isLoading: false }));

      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");

          return;
        }
      }
      toast.error("Failed to get data");
    });
  }

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, pageState.search, pageState.sortModel]);

  return (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Audit Trail Data" />
        <DataGrid
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          apiRef={apiRef}
          columns={columns}
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          initialState={{ 
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={(params) => {
            setPageState(prev => ({ ...prev, page: params.page + 1, pageSize: params.pageSize }));
          }}
          sortingMode="server"
          onSortModelChange={(params) => {
            setPageState(prev => ({ ...prev, sortModel: params }));
          }}
          filterMode="server"
          onFilterModelChange={(params) => {
            setPageState(prev => ({ ...prev, search: params.quickFilterValues[0] ? params.quickFilterValues[0] : "" }));
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
    </div>
  );
}
export default AuditTrail;

