import dateFormatter from "./dateFormatter";

const getCurrentDate = () => {
  const currentDate = new Date();

  const timestamp = currentDate.getTime();

  return {
    date: dateFormatter(currentDate),
    timestamp: timestamp,
  };
}

export default getCurrentDate;