import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { FaXmark } from "react-icons/fa6";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegEdit, FaPlus } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./integratedPlatform.css";
import { API_ROOT } from "../../../config";
import BlankProfile from "../../../data/blank.png";
import { PageLoading, DeleteValidation } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

const Details = () => {

  const { uuid } = useParams();
  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const [pageLoading, setPageLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [deleteValidation, setDeleteValidation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const gridRef = useRef(null);
  
  const [toggleEdit, setToggleEdit] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editPlatformData, setEditPlatformData] = useState(null);
  const [editPlatformWarning, setEditPlatformWarning] = useState(null);
  const editPlatformPattern = {
    customer_key: /^[a-zA-Z0-9_]+$/,
  };

  const [toggleAddMember, setToggleAddMember] = useState(false);
  const [addMemberData, setAddMemberData] = useState(null);
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [addMemberWarning, setAddMemberWarning] = useState(null);
  
  const [editMember, setEditMember] = useState(null);
  const [editMemberLoading, setEditMemberLoading] = useState(false);
  const [deleteMemberValidation, setDeleteMemberValidation] = useState(false);

  const fetchPaymentMethodMasterdata = async () => {
    await axios.get(API_ROOT + "/admin/masterdata-management/payment-methods", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const mainData = response.data.data;

      setPaymentMethod(mainData);

    }).catch((error) => {
      toast.error("Failed to fetch data");
      
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const fetchDetails = async (uuid) => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/details/" + uuid, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const mainData = response.data.data;

      setDetailData(mainData);

      setMemberData(() => mainData.integrated_members.map((item, index) => {
        return (
          {
            No: index+1,
            Uuid: item.user.uuid,
            Name: item.user.name,
            Username: item.user.username,
            Email: item.user.email,
            PhoneNumber: item.user.phone_number,
            IsActive: item.is_member,
            IsActiveText: item.is_member ? "Active" : "Inactive",
            PaymentMethod: item.payment_method.payment_method_name,
            PaymentMethodKey: item.payment_method.key_payment_method,
          }
        );
      }));

      window.scrollTo({ top: 0, left: 0});
    }).catch((error) => {
      toast.error("Failed to open data");
      
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    }).finally(() => setPageLoading(false));
  }

  useEffect(() => {
    fetchPaymentMethodMasterdata();
    fetchDetails(uuid);
  }, [uuid]);

  useEffect(() => {
    async function searchUser(value) {
      if (value) {
        await axios.get(API_ROOT + "/admin/user/search?keywords=" + value, {
          headers: {
            Authorization: getCookie("admin_auth"),
          },
        }).then((response) => {
          const userList = response.data.data;
    
          setEditPlatformData((prev) => ({ ...prev, owner_list: userList}));

        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              navigate("/login");
    
              return;
            }
          }
          setEditPlatformData((prev) => ({ ...prev, owner_list: []}));
          toast.error("Failed to get data");
        })
      } else {
        setEditPlatformData((prev) => ({ ...prev, owner_list: []}));
      }
    }

    const timeout = setTimeout(() => {
      if (editPlatformData) {
        searchUser(editPlatformData.owner_temp);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [editPlatformData && editPlatformData.owner_temp]);

  useEffect(() => {
    async function searchUser(value) {
      if (value) {
        await axios.get(API_ROOT + "/admin/user/search?keywords=" + value, {
          headers: {
            Authorization: getCookie("admin_auth"),
          },
        }).then((response) => {
          const userList = response.data.data;
    
          setAddMemberData((prev) => ({ ...prev, member_list: userList}));

        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              navigate("/login");
    
              return;
            }
          }
          setAddMemberData((prev) => ({ ...prev, member_list: []}));
          toast.error("Failed to get data");
        })
      } else {
        setAddMemberData((prev) => ({ ...prev, member_list: []}));
      }
    }

    const timeout = setTimeout(() => {
      if (addMemberData) {
        searchUser(addMemberData.member_temp);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [addMemberData && addMemberData.member_temp]);

  const actionTemplate = (props) => {
    return (
      <div className="e-actionParent">
        <button
          className="e-action"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            setEditMember(props);
          }}
        >
          <FaRegEdit className="e-actionIcon" />
          <div>Edit</div>
        </button>
      </div>
    );
  };

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  };

  const dataBound = () => {
    if (gridRef.current) {
      gridRef.current.autoFitColumns();
    }
  };

  return pageLoading ? (<PageLoading />) : (
    <div className="flex flex-col gap-4 m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate("/user/integrated-platform")}
          >
            <IoMdArrowBack className="w-6 h-6" />
          </button>
          <h1 className="text-xl font-medium">Platform Details</h1>
        </div>
        <button
          style={{ backgroundColor: currentColor }}
          className="text-white w-36 py-2 px-4 rounded-md text-sm"
          onClick={() => {
            setEditPlatformData({
              platform_name: detailData.platform_name,
              customer_key: detailData.customer_key,
              selected_owner: {
                uuid: detailData.owner.uuid,
                name: detailData.owner.name,
                username: detailData.owner.username,
                image: detailData.owner.image,
              },
              owner_temp: null,
              owner_list: [],
            });
            setEditPlatformWarning({
              platform_name: null,
              customer_key: null,
              selected_owner: null,
            });
            setToggleEdit(true);
          }}
        >
          Edit Platform
        </button>
      </div>
      <hr />
      <div className="flex flex-col gap-1">
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Platform Name</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.platform_name}</div>
        </div>
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Customer Key</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.customer_key}</div>
        </div>
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Secret Key</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.secret_key}</div>
        </div>
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Is Active</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.is_active ? "True" : "False"}</div>
        </div>
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Owner Name</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.owner.name}</div>
        </div>
        <div className="flex">
          <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Owner Username</div>
          <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{detailData.owner.username}</div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-4 mt-4">
        <button
          disabled={activateLoading || deleteLoading}
          className={"text-white w-44 py-2 px-4 rounded-md text-sm " + (detailData.is_active ? "bg-amber-500 hover:bg-amber-600" : "bg-green-500 hover:bg-green-600")}
          onClick={async () => {
            setActivateLoading(true);

            await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
              platform_name: null,
              customer_key: null,
              is_active: !detailData.is_active,
              owner: null,
              members: null,
            },{
              headers: {
                Authorization: getCookie("admin_auth"),
              },
            }).then(async () => {
              toast.success(detailData.is_active ? "Platform Deactivated!" : "Platform Activated", {
                onOpen: () => fetchDetails(uuid),
              });
            }).catch((error) => {

              toast.error("Failed to update platform!");
              
              if (error.response) {
                if (error.response.status == 401) {
                  navigate("/login");
                }
              }
            });

            setActivateLoading(false);
          }}
        >
          {activateLoading || deleteLoading ? "Loading..." : detailData.is_active ? "Deactivate Platform" : "Activate Platform"}
        </button>
        <button
          disabled={deleteLoading || activateLoading}
          className="text-white w-40 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
          onClick={() => setDeleteValidation(true)}
        >
          {deleteLoading || activateLoading ? "Loading..." : "Delete Platform"}
        </button>
        {
          deleteValidation && (
            <DeleteValidation
              title={<div className="text-center"><p>{detailData.platform_name}</p><p>Are you sure want to delete platform?</p></div>}
              falseHandler={() => setDeleteValidation(false)}
              trueHandler={async () => {

                setDeleteLoading(true);
  
                await axios.delete(API_ROOT + "/admin/user/integrated-platform/manage/delete/" + detailData.uuid, {
                  headers: {
                    Authorization: getCookie("admin_auth"),
                  },
                }).then(async () => {
                  toast.success("Platform Deleted!", {
                    autoClose: 500,
                    onClose: () => navigate("/user/integrated-platform"),
                  });
                }).catch((error) => {
  
                  toast.error("Failed to delete platfrom!");
                  
                  if (error.response) {
                    if (error.response.status == 401) {
                      navigate("/login");
                    }
                  }
                });
  
                setDeleteLoading(false);
                setDeleteValidation(false);
              }}
            />
          )
        }
      </div>
      {
        toggleEdit && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full overflow-y-auto py-40 px-[100px] bg-slate-800/50">
            <div className="bg-white flex flex-col gap-2 w-full max-w-[464px] h-fit rounded-xl p-8">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-medium">Edit Platform</h1>
                <div
                  className="cursor-pointer p-2"
                  onClick={() =>  setToggleEdit(false)}
                >
                  &times;
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-4 w-fit">
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Platform Name</h4>
                  <input
                    type="text"
                    placeholder="Input platform name..."
                    maxLength={100}
                    spellCheck="false"
                    className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!editPlatformWarning.platform_name ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                    value={editPlatformData.platform_name}
                    onChange={(e) => {
                      setEditPlatformData((prev) => ({ ...prev, platform_name: e.target.value}));

                      if (e.target.value.length < 1) {
                        setEditPlatformWarning((prev) => ({ ...prev, platform_name: "Minimum of 1 character is required" }));
                      } else {
                        setEditPlatformWarning((prev) => ({ ...prev, platform_name: null }));
                      }
                    }}
                  />
                  {
                    editPlatformWarning.platform_name && (
                      <p className="text-xs text-red-500">{editPlatformWarning.platform_name}</p>
                    )
                  }
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Customer Key</h4>
                  <input
                    type="text"
                    placeholder="Input customer key..."
                    maxLength={60}
                    spellCheck="false"
                    className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!editPlatformWarning.customer_key ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                    value={editPlatformData.customer_key}
                    onChange={(e) => {
                      setEditPlatformData((prev) => ({ ...prev, customer_key: e.target.value}));

                      if (e.target.value.length < 1) {
                        setEditPlatformWarning((prev) => ({ ...prev, customer_key: "Minimum of 1 character is required" }));
                      } else if (!editPlatformPattern.customer_key.test(e.target.value)) {
                        setEditPlatformWarning((prev) => ({ ...prev, customer_key: "Input contain not allowed characters or combinations" }));
                      } else {
                        setEditPlatformWarning((prev) => ({ ...prev, customer_key: null }));
                      }
                    }}
                  />
                  {
                    editPlatformWarning.customer_key && (
                      <p className="text-xs text-red-500">{editPlatformWarning.customer_key}</p>
                    )
                  }
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium">Owner</h4>
                  {
                    editPlatformData.selected_owner ? (
                      <div className="flex justify-between items-center bg-white py-3 px-4 border border-slate-500 rounded-md">
                        <div className="flex gap-2 items-center">
                          <div>
                            <img
                              src={editPlatformData.selected_owner.image ? "data:image/*;base64,"+editPlatformData.selected_owner.image : BlankProfile}
                              alt="User profile image"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                          <div className="fles flex-col justify-center text-sm">
                            <h1>{editPlatformData.selected_owner.name}</h1>
                            <h3 className="text-slate-700 font-semibold">{"@" + editPlatformData.selected_owner.username}</h3>
                          </div>
                        </div>
                        <button
                          className="p-2 cursor-pointer"
                          onClick={() => {
                            setEditPlatformData((prev) => ({ ...prev, selected_owner: null}));
                          }}
                        >
                          <FaXmark className="w-3 h-3 text-slate-800" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Search User"
                          spellCheck="false"
                          className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!editPlatformWarning.selected_owner ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                          value={editPlatformData.owner_temp}
                          onChange={(e) => {
                            setEditPlatformData((prev) => ({ ...prev, owner_temp: e.target.value}));
                            setEditPlatformWarning((prev) => ({ ...prev, selected_owner: null}));
                          }}
                        />
                        {
                          editPlatformData.owner_list.length !== 0 && (
                            <div className="flex flex-col w-[400px] max-h-[192px] border border-slate-500 overflow-y-auto rounded-md cursor-pointer">
                              {
                                editPlatformData.owner_list.map((item, index) => (
                                  <div
                                    key={"owner"+index}
                                    className="py-3 px-4 flex justify-between items-center bg-white hover:bg-slate-200 active:bg-slate-50"
                                    onClick={() => {
                                      setEditPlatformData((prev) => ({ ...prev, selected_owner: item, owner_temp: "", owner_list: []}));
                                      setEditPlatformWarning((prev) => ({ ...prev, selected_owner: null}));
                                    }}
                                  >
                                    <div className="flex gap-2 items-center">
                                      <div>
                                        <img
                                          src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                          alt="User profile image"
                                          className="w-10 h-10 rounded-full object-cover"
                                        />
                                      </div>
                                      <div className="fles flex-col justify-center text-sm">
                                        <h1>{item.name}</h1>
                                        <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                      </div>
                                    </div>
                                    <div>
                                      <FaPlus className="w-3 h-3 text-slate-800" />
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    editPlatformWarning.selected_owner && (
                      <p className="text-xs text-red-500">{editPlatformWarning.selected_owner}</p>
                    )
                  }
                </div>
                <div className="flex justify-end items-center">
                  <button
                    style={{ backgroundColor: currentColor }}
                    className="text-white w-36 mt-4 py-2 px-4 rounded-md text-sm"
                    onClick={async () => {
                      setEditLoading(true);

                      if (!editPlatformData.platform_name) {
                        setEditPlatformWarning((prev) => ({ ...prev, platform_name: "Platform name can't be empty"}));
                        setEditLoading(false);

                        return;
                      }

                      if (!editPlatformData.customer_key) {
                        setEditPlatformWarning((prev) => ({ ...prev, customer_key: "Customer key can't be empty"}));
                        setEditLoading(false);

                        return;
                      }

                      if (!editPlatformData.selected_owner) {
                        setEditPlatformWarning((prev) => ({ ...prev, selected_owner: "Owner can't be empty"}));
                        setEditLoading(false);

                        return;
                      }

                      await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
                        platform_name: editPlatformData.platform_name,
                        customer_key: editPlatformData.customer_key,
                        owner: editPlatformData.selected_owner.uuid,
                        is_active: null,
                        members: null,
                      },{
                        headers: {
                          Authorization: getCookie("admin_auth"),
                        },
                      }).then(async () => {
                        setToggleEdit(false);
                        toast.success("Platform Updated!", {
                          onOpen: () => fetchDetails(uuid),
                        });
                      }).catch((error) => {

                        toast.error("Failed to update platform!");
                        
                        if (error.response) {
                          if (error.response.status == 401) {
                            navigate("/login");
                          }
                        }
                      });

                      setEditLoading(false);
                    }}
                  >
                    {!editLoading ? "Save" : "Loading..."}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <hr />
      <div className="flex items-center gap-6">
        <h1 className="text-xl font-medium">Members ({detailData.integrated_members.length})</h1>
        <button
          style={{ backgroundColor: currentColor }}
          className="text-white w-36 py-2 px-4 rounded-md text-sm"
          onClick={() => {
            setAddMemberData({
              selected_member: [],
              member_temp: null,
              member_list: [],
            });
            setToggleAddMember(true);
          }}
        >
          Add Members
        </button>
      </div>
      {
        toggleAddMember && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full overflow-y-auto py-40 px-[100px] bg-slate-800/50">
            <div className="bg-white flex flex-col gap-2 w-full max-w-[464px] h-fit rounded-xl p-8">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-medium">Add Members</h1>
                <div
                  className="cursor-pointer p-2"
                  onClick={() =>  setToggleAddMember(false)}
                >
                  &times;
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-4 w-fit">
                <div className="flex flex-col gap-2">
                  {
                    addMemberData.selected_member.length > 0 && (
                      <div className="flex flex-col bg-white border border-slate-500 rounded-md">
                        {
                          addMemberData.selected_member.map((item, index) => (
                            <div key={"selectedMember"+index} className="flex justify-between items-center py-3 px-4">
                              <div className="flex gap-2 items-center">
                                <div>
                                  <img
                                    src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                    alt="User profile image"
                                    className="w-10 h-10 rounded-full object-cover"
                                  />
                                </div>
                                <div className="fles flex-col justify-center text-sm">
                                  <h1>{item.name}</h1>
                                  <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                </div>
                              </div>
                              <button
                                className="p-2 cursor-pointer"
                                onClick={() => {
                                  setAddMemberData((prev) => {
                                    const newSelectedMembers = [...prev.selected_member];
                                    newSelectedMembers.splice(index, 1);

                                    return { ...prev, selected_member: newSelectedMembers };
                                  });
                                }}
                              >
                                <FaXmark className="w-3 h-3 text-slate-800" />
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    )	
                  }
                  <input
                    type="text"
                    placeholder="Search User"
                    spellCheck="false"
                    className={"w-[400px] p-2 text-sm rounded-[4px] border focus:outline-2 " + (!addMemberWarning ? "border-slate-500 focus:outline-sky-500" : "border-red-500 focus:outline-red-500")}
                    value={addMemberData.member_temp}
                    onChange={(e) => {
                      setAddMemberData((prev) => ({ ...prev, member_temp: e.target.value }));
                      setAddMemberWarning(null);
                    }}
                  />
                  {
                    addMemberWarning && (
                      <p className="text-xs text-red-500">{addMemberWarning}</p>
                    )
                  }
                  {
                    addMemberData.member_list.length !== 0 && (
                      <div className="flex flex-col w-[400px] max-h-[192px] border border-slate-500 overflow-y-auto rounded-md cursor-pointer">
                        {
                          addMemberData.member_list.map((item, index) => (
                            <div
                              key={"member"+index}
                              className="py-3 px-4 flex justify-between items-center bg-white hover:bg-slate-200 active:bg-slate-50"
                              onClick={() => {
                                if (!addMemberData.selected_member.some((member) => member.uuid === item.uuid) && !detailData.integrated_members.some((member) => member.user.uuid === item.uuid)) {
                                  setAddMemberData((prev) => ({ ...prev, selected_member: [...prev.selected_member, item], member_temp: "", member_list: [] }));
                                }
                              }}
                            >
                              <div className="flex gap-2 items-center">
                                <div>
                                  <img
                                    src={item.image ? "data:image/*;base64,"+item.image : BlankProfile}
                                    alt="User profile image"
                                    className="w-10 h-10 rounded-full object-cover"
                                  />
                                </div>
                                <div className="fles flex-col justify-center text-sm">
                                  <h1>{item.name}</h1>
                                  <h3 className="text-slate-700 font-semibold">{"@" + item.username}</h3>
                                </div>
                              </div>
                              <div>
                                <FaPlus className="w-3 h-3 text-slate-800" />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
                <div className="flex justify-end items-center">
                  <button
                    disabled={addMemberLoading || addMemberData.selected_member.length < 1}
                    style={{ backgroundColor: currentColor }}
                    className="text-white w-36 mt-4 py-2 px-4 rounded-md text-sm"
                    onClick={async () => {
                      if (addMemberData.selected_member.length < 1) return;
                      
                      setAddMemberLoading(true);

                      const prabayarMethod = paymentMethod.filter((item) => item.key_payment_method == "prabayar")[0];

                      const currentMember = detailData.integrated_members.map((item) => ({
                        user_uuid: item.user.uuid,
                        is_member: item.is_member,
                        payment_method_uuid: item.payment_method.uuid,
                      }));

                      const newMember = addMemberData.selected_member.map((item) => ({
                        user_uuid: item.uuid,
                        is_member: true,
                        payment_method_uuid: prabayarMethod.uuid,
                      }));

                      await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
                        platform_name: null,
                        customer_key: null,
                        owner: null,
                        is_active: null,
                        members: currentMember.concat(newMember),
                      },{
                        headers: {
                          Authorization: getCookie("admin_auth"),
                        },
                      }).then(async () => {
                        setToggleAddMember(false);
                        toast.success("Members Updated!", {
                          onOpen: () => fetchDetails(uuid),
                        });
                      }).catch((error) => {

                        toast.error("Failed to update members!");

                        if (error.response) {
                          if (error.response.status == 401) {
                            navigate("/login");
                          } else if (error.response.data.err.type === "service") {
                            if (error.response.data.err.data.code === -4) {
                              setAddMemberWarning("Owner cannot be member");
                            }
                          }
                        }
                      });

                      setAddMemberLoading(false);
                    }}
                  >
                    {!addMemberLoading ? "Add" : "Loading..."}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="overflow-x-auto">
        <div className="w-fit">
          <GridComponent
            dataSource={memberData}
            width="auto"
            allowPaging
            allowSorting
            allowTextWrap={true}
            pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
            textWrapSettings={{ wrapMode: 'Content' }}
            toolbar={["Search"]}
            queryCellInfo={queryCellInfo}
            dataBound={dataBound}
            ref={gridRef}
          >
            <ColumnsDirective>
              <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
              <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
              <ColumnDirective field="Name" headerText="Name" textAlign="Left" />
              <ColumnDirective field="Username" headerText="Username" textAlign="Left" />
              <ColumnDirective field="Email" headerText="Email" textAlign="Left" />
              <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" />
              <ColumnDirective field="IsActive" visible={false} />
              <ColumnDirective field="IsActiveText" headerText="Member Status" textAlign="Center" />
              <ColumnDirective field="PaymentMethod" headerText="Payment Method" textAlign="Center" />
              <ColumnDirective field="PaymentMethodKey" visible={false} />
            </ColumnsDirective>
            <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
          </GridComponent>
        </div>
      </div>
      {
        editMember && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full py-40 px-[100px] bg-slate-800/50">
            <div className="flex flex-col gap-4 bg-white w-full max-w-[1000px] h-fit rounded-xl p-8">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-medium">Edit Member</h1>
                <div
                  className="cursor-pointer p-2"
                  onClick={() => {
                    setEditMember(null);
                  }}
                >
                  &times;
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-1">
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Name</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.Name}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Username</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.Username}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Email</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.Email}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Phone Number</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.PhoneNumber}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Member Status</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.IsActiveText}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-40 bg-gray-600 text-white pl-2 py-1 flex items-center">Payment Method</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{editMember.PaymentMethod}</div>
                </div>
              </div>
              <div className="flex justify-end items-stretch gap-4 mt-4">
                <button
                  disabled={editMemberLoading}
                  className="text-white w-44 py-2 px-4 rounded-md text-sm bg-sky-500 hoveer:bg-sky-600"
                  onClick={async () => {
                    setEditMemberLoading(true);

                    const prabayarMethod = paymentMethod.filter((item) => item.key_payment_method == "prabayar")[0];
                    const pascabayarMethod = paymentMethod.filter((item) => item.key_payment_method == "pascabayar")[0];

                    const editedMembers = detailData.integrated_members.map((member) => {
                      if (member.user.uuid == editMember.Uuid) {
                        if (member.payment_method.key_payment_method == prabayarMethod.key_payment_method) {
                          return (
                            {
                              user_uuid: member.user.uuid,
                              is_member: member.is_member,
                              payment_method_uuid: pascabayarMethod.uuid,
                            }
                          );
                        } else {
                          return (
                            {
                              user_uuid: member.user.uuid,
                              is_member: member.is_member,
                              payment_method_uuid: prabayarMethod.uuid,
                            }
                          );
                        }
                      } else {
                        return (
                          {
                            user_uuid: member.user.uuid,
                            is_member: member.is_member,
                            payment_method_uuid: member.payment_method.uuid,
                          }
                        );
                      }
                    });

                    await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
                      platform_name: null,
                      customer_key: null,
                      is_active: null,
                      owner: null,
                      members: editedMembers,
                    },{
                      headers: {
                        Authorization: getCookie("admin_auth"),
                      },
                    }).then(async () => {
                      setEditMember(null);
                      toast.success("Member method updated!", {
                        onOpen: () => fetchDetails(uuid),
                      });
                    }).catch((error) => {

                      toast.error("Failed to update method!");
                      
                      if (error.response) {
                        if (error.response.status == 401) {
                          navigate("/login");
                        }
                      }
                    });

                    setEditMemberLoading(false);
                  }}
                >
                  {editMemberLoading ? "Loading..." : editMember.PaymentMethodKey === "prabayar" ? "Change Method to Pascabayar" : "Change Method to Prabayar"}
                </button>
                <button
                  disabled={editMemberLoading}
                  className={"text-white w-44 py-2 px-4 rounded-md text-sm " + (editMember.IsActive ? "bg-amber-500 hover:bg-amber-600" : "bg-green-500 hover:bg-green-600")}
                  onClick={async () => {
                    setEditMemberLoading(true);

                    const editedMembers = detailData.integrated_members.map((member) => {
                      if (member.user.uuid == editMember.Uuid) {
                        return (
                          {
                            user_uuid: member.user.uuid,
                            is_member: !member.is_member,
                            payment_method_uuid: member.payment_method.uuid,
                          }
                        );
                      } else {
                        return (
                          {
                            user_uuid: member.user.uuid,
                            is_member: member.is_member,
                            payment_method_uuid: member.payment_method.uuid,
                          }
                        );
                      }
                    });

                    await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
                      platform_name: null,
                      customer_key: null,
                      is_active: null,
                      owner: null,
                      members: editedMembers,
                    },{
                      headers: {
                        Authorization: getCookie("admin_auth"),
                      },
                    }).then(async () => {
                      setEditMember(null);
                      toast.success(detailData.is_active ? "Member Deactivated!" : "Member Activated", {
                        onOpen: () => fetchDetails(uuid),
                      });
                    }).catch((error) => {

                      toast.error("Failed to update member!");
                      
                      if (error.response) {
                        if (error.response.status == 401) {
                          navigate("/login");
                        }
                      }
                    });

                    setEditMemberLoading(false);
                  }}
                >
                  {editMemberLoading ? "Loading..." : editMember.IsActive ? "Deactivate Member" : "Activate Member"}
                </button>
                <button
                  disabled={editMemberLoading}
                  className="text-white w-40 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                  onClick={() => setDeleteMemberValidation(true)}
                >
                  {editMemberLoading ? "Loading..." : "Remove Member"}
                </button>
                {
                  deleteMemberValidation && (
                    <DeleteValidation
                      title={<div className="text-center"><p>{editMember.Name}</p><p>Are you sure want to remove this member?</p></div>}
                      falseHandler={() => setDeleteMemberValidation(false)}
                      trueHandler={async () => {

                        setEditMemberLoading(true);

                        const restMember = detailData.integrated_members.filter((member) => member.user.uuid !== editMember.Uuid);

                        const restData = restMember.map((member) => ({
                          user_uuid: member.user.uuid,
                          is_member: member.is_member,
                          payment_method_uuid: member.payment_method.uuid,
                        }));
          
                        await axios.put(API_ROOT + "/admin/user/integrated-platform/manage/update/" + detailData.uuid, {
                          platform_name: null,
                          customer_key: null,
                          owner: null,
                          is_active: null,
                          members: restData,
                        },{
                          headers: {
                            Authorization: getCookie("admin_auth"),
                          },
                        }).then(async () => {
                          setEditMember(null);
                          toast.success("Members deleted!", {
                            onOpen: () => fetchDetails(uuid),
                          });
                        }).catch((error) => {

                          toast.error("Failed to delete members!");
                          
                          if (error.response) {
                            if (error.response.status == 401) {
                              navigate("/login");
                            }
                          }
                        });
          
                        setEditMemberLoading(false);
                        setDeleteMemberValidation(false);
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Details;