import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie, deleteCookie } from "cookies-next";

import { API_ROOT } from "../../config";

const Login = () => {
	const navigate = useNavigate();

	const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
	const [warning, setWarning] = useState("");

	useEffect(() => {
		deleteCookie("admin_auth")
	}, [])

	return (
		<div className="bg-gray-800 w-screen h-screen flex justify-center items-center">
			<div className="bg-gray-900 w-[300px] md:w-[600px] rounded-lg px-8 md:px-12 lg:px-16 pt-16 pb-10">
				<h1 className="text-center text-white text-2xl md:text-4xl mb-10 font-bold tracking-wider">
					PAPERLESS ADMIN
				</h1>
				<form className="flex flex-col">
					<label className="text-white text-base ml-1">Username</label>
					<input
						className="mt-2 mb-6 h-8 bg-inherit border-b-cyan-500 border-b-[1px] text-white px-1 focus:outline-none"
						type="text"
						onChange={(val) => {
							setWarning("");
							setUsername(val.currentTarget.value);
						}}
					/>
					<label className="text-white text-base ml-1">Password</label>
					<input
						className="mt-2 mb-8 h-8 bg-inherit border-b-cyan-500 border-b-[1px] text-white px-1 focus:outline-none"
						type="password"
						onChange={(val) => {
							setWarning("");
							setPassword(val.currentTarget.value);
						}}
					/>
					<p className="text-red-600 text-center mb-8">{warning}</p>
					<button
						className="text-cyan-500 border-cyan-500 border-[1px] self-center py-2 px-4 rounded-lg hover:bg-cyan-500 hover:text-white"
						type="submit"
						onClick={(e) => {
							e.preventDefault();

							if (!username || !password) return;

							axios.post(API_ROOT + "/admin/auth/login", {
								username,
								password
							}).then((response) => {
								if (response.status === 200) {
									const admin_auth = response.data.data;

									setCookie("admin_auth", admin_auth, {
										expires: new Date(new Date().getTime() + 10800000),
									});

									navigate("/");
								}
							}).catch((error) => {
								if (error.response.data.err.data.code === -1) {
									setWarning("Username or password is wrong"); 
								} else {
									setWarning("Internal Server Error, Try again later !")
								}
							});
						}}
					>
						Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default Login;