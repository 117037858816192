import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { API_ROOT } from "../../../config";
import { Header } from "../../../components";
import { PageLoading } from "../../../components";

const PaymentMethods = () => {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [activateLoading, setActivateLoading] = useState(-1);

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/masterdata-management/payment-methods", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setData(response.data.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return !data ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Payment Methods Configuration" />
      <div className="flex flex-col gap-4">
        {
          data.map((item, index) => (
            <div key={"methods"+index} className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex">
                  <div className="flex-none w-48 bg-gray-600 text-white pl-2 py-1 flex items-center">Payment Method Name</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{item.payment_method_name}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-48 bg-gray-600 text-white pl-2 py-1 flex items-center">Key Payment Method</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{item.key_payment_method}</div>
                </div>
                <div className="flex">
                  <div className="flex-none w-48 bg-gray-600 text-white pl-2 py-1 flex items-center">Is Active</div>
                  <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{item.is_active ? "True" : "False"}</div>
                </div>
              </div>
              <div className="flex justify-end items-center">
                <button
                  disabled={activateLoading == index}
                  className={"text-white w-44 py-2 px-4 rounded-md text-sm " + (item.is_active ? "bg-amber-500 hover:bg-amber-600" : "bg-green-500 hover:bg-green-600")}
                  onClick={async () => {
                    setActivateLoading(index);

                    await axios.put(API_ROOT + "/admin/masterdata-management/payment-methods/" + item.uuid, {
                      key_payment_method: item.key_payment_method,
                      payment_method_name: item.payment_method_name,
                      is_active: !item.is_active,
                    },{
                      headers: {
                        Authorization: getCookie("admin_auth"),
                      },
                    }).then(async () => {
                      toast.success(item.is_active ? "Method Deactivated!" : "Method Activated", {
                        onOpen: fetchData,
                      });
                    }).catch((error) => {

                      toast.error("Failed to update method!");
                      
                      if (error.response) {
                        if (error.response.status == 401) {
                          navigate("/login");
                        }
                      }
                    });

                    setActivateLoading(-1);
                  }}
                >
                  {activateLoading == index ? "Loading..." : item.is_active ? "Deactivate Method" : "Activate Method"}
                </button>
              </div>
              {
                (index !== data.length - 1) && (
                  <hr className="border border-gray-400" />
                )
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PaymentMethods;