import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { IoMdArrowBack } from "react-icons/io";
import { FiCheckCircle } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./pascabayarPayment.css";
import { API_ROOT } from "../../../config";
import InvoiceTemplate from "./InvoiceTemplate";
import { PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

import formatDate from "../../../handlers/formatDate";
import dateFormatter from "../../../handlers/dateFormatter";
import convertToTimestamp from "../../../handlers/convertToTimestamp";

const PascabayarPayment = () => {

  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const qStartDate = searchParams.get("startDate");
  const qEndDate = searchParams.get("endDate");

  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const abortController = new AbortController();

  const [pageLoading, setPageLoading] = useState(true);
  const [startDate, setStartDate] = useState({
    date: dateFormatter(new Date(Number(qStartDate))),
    timestamp: qStartDate
  });
  const [endDate, setEndDate] = useState({
    date: dateFormatter(new Date(Number(qEndDate))),
    timestamp: qEndDate
  });
  const [invalid, setInvalid] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [resultPrice, setResultPrice] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const gridRef = useRef(null);

  const fetchData = async (startDate, endDate) => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/recap/pascabayar/document-list?platformUUID=" + uuid + "&startDate=" + startDate + "&endDate=" + endDate + "&is_penagihan=0", {
      signal: abortController.signal,
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setInvalid(false);

      const documentList = response.data.data;

      setDocumentData(() => documentList.map((item, index) => (
        {
          Uuid: item.uuid,
          No: index+1,
          Title: item.title,
          PricePascabayar: item.price_pascabayar,
          CreatedAt: formatDate(item.created_at),
          SignerName: item.document_recipients.map(item => item.user.name),
          SignerUsername: item.document_recipients.map(item => item.user.username),
          SignerEmail: item.document_recipients.map(item => item.user.email),
          SignerPhone: item.document_recipients.map(item => item.user.phone_number),
          SignerUuid: item.document_recipients.map(item => item.user.uuid),
        }
      )));
    }).catch((error) => {
      if (error.code == "ERR_CANCELED") return;
      else if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.err && error.response.data.err.data) {
          if (error.response.data.err.data.code == -1 || error.response.data.err.data.code == -2) {
            setInvalid(true);
          } else {
            toast.error("Failed to open data");
          }
        } else {
          toast.error("Failed to open data");
        }
      } else {
        toast.error("Failed to open data");
      }
    }).finally(() => {
      setLoading(false);
      setPageLoading(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        setLoading(true);
        
        fetchData(startDate.timestamp, endDate.timestamp);
      }
    }, 750);

    return () => {
      abortController.abort();
      clearTimeout(timeout);
    };
  }, [startDate, endDate]);

  const priceTemplate = (props) => {
    return (<>{"Rp " + props.PricePascabayar.toLocaleString("id-ID")}</>);
  }

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  };

  const dataBound = () => {
    if (gridRef.current) {
      gridRef.current.autoFitColumns(["Title", "PricePascabayar", "CreatedAt", "SignerName", "SignerUsername", "SignerEmail", "SignerPhone"]);
    }
  };

  const toolbarClick = (args) => {
    if (gridRef.current && args.item.id.includes("excelexport")) {
      const excelExportProperties = {
        fileName: "Paperless - Digitalisasi Untuk Negeri Document Details Data.xlsx"
      };
      gridRef.current.excelExport(excelExportProperties);
    }
  }

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate("/user/pascabayar-payment")}
          >
            <IoMdArrowBack className="w-6 h-6" />
          </button>
          <h1 className="text-xl font-medium">Pascabayar Payment Details</h1>
        </div>
        <hr />
        <div className="flex items-center gap-4">
          <div>Filter Tanggal</div>
          <div>-</div>
          <div className="flex items-center gap-2">
            <input 
              type="date"
              className="p-2 border rounded-md"
              style={{ borderColor: currentColor, outlineColor: currentColor }}
              value={startDate.date}
              onChange={(e) => {
                setStartDate({
                  date: e.target.value,
                  timestamp: convertToTimestamp(e.target.value, 0, 0, 0)
                })
              }}
            />
            <h2>s/d</h2>
            <input 
              type="date"
              className="p-2 border rounded-md"
              style={{ borderColor: currentColor, outlineColor: currentColor }}
              value={endDate.date}
              onChange={(e) => {
                setEndDate({
                  date: e.target.value,
                  timestamp: convertToTimestamp(e.target.value, 23, 59, 59)
                })
              }}
            />
          </div>
        </div>
        {
          loading ? (
            <div className="text-center mt-4">Loading...</div>
          ) : invalid ? (
            <div className="text-center mt-4">Filter Tanggal Invalid</div>
          ) : documentData.length == 0 ? (
            <div className="text-center mt-4">Data Kosong</div>
          ) : (
            <>
              <button
                style={{ backgroundColor: currentColor }}
                className="text-white w-36 py-2 px-4 rounded-md text-sm"
                onClick={() => setToggleCreate(true)}
              >
                Create Invoice
              </button>
              <div className="overflow-x-auto">
                <div className="w-fit">
                  <GridComponent
                    dataSource={documentData}
                    width="auto"
                    allowPaging
                    allowSorting
                    allowTextWrap={true}
                    pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                    textWrapSettings={{ wrapMode: 'Content' }}
                    toolbar={["Search", "ExcelExport"]}
                    queryCellInfo={queryCellInfo}
                    allowExcelExport={true}
                    toolbarClick={toolbarClick}
                    dataBound={dataBound}
                    ref={gridRef}
                  >
                    <ColumnsDirective>
                      <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                      <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                      <ColumnDirective field="Title" headerText="Title" textAlign="Left" />
                      <ColumnDirective field="PricePascabayar" headerText="Price" textAlign="Center" template={priceTemplate} />
                      <ColumnDirective field="CreatedAt" headerText="Created Date" textAlign="Center" />
                      <ColumnDirective field="SignerName" headerText="Signer Name" textAlign="Left" />
                      <ColumnDirective field="SignerUsername" headerText="Signer Username" textAlign="Left" />
                      <ColumnDirective field="SignerEmail" headerText="Signer Email" textAlign="Left" />
                      <ColumnDirective field="SignerPhone" headerText="Signer Phone" textAlign="Left" />
                    </ColumnsDirective>
                    <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
                  </GridComponent>
                </div>
              </div>
              {
                toggleCreate && (
                  <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full overflow-y-auto py-40 px-[100px] bg-slate-800/50">
                    <div className="bg-white flex flex-col gap-2 w-full max-w-[464px] h-fit rounded-xl p-8">
                      <div className="flex items-center justify-between">
                        <h1 className="text-xl font-medium">Create Invoice</h1>
                        <div
                          className="cursor-pointer p-2"
                          onClick={() => {
                            setToggleCreate(false);
                            setDescription(null);
                            setDiscount(null);
                            setResultPrice(null);
                          }}
                        >
                          &times;
                        </div>
                      </div>
                      <hr />
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                          <h1>Description</h1>
                          <textarea
                            rows="5"
                            maxLength={250}
                            placeholder="add description..."
                            className="w-full border border-gray-400 px-4 py-2 text-sm rounded-md"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="flex flex-col gap-2">
                          <h1>Discount</h1>
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <input
                                type="number"
                                className="w-20 border border-gray-400 px-2 py-2 text-sm rounded-md"
                                min="0"
                                max="100"
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                value={discount}
                                onChange={(e) => {
                                  const input = e.target.value;

                                  if (input > 100) {
                                    setDiscount(100);
                                  } else {
                                    setDiscount(input);
                                    setResultPrice(null);
                                  }
                                }}
                              />
                              <h1 className="text-xl">%</h1>
                            </div>
                            <button
                              style={{ backgroundColor: currentColor }}
                              className="text-white w-20 py-2 rounded-md text-xs"
                              onClick={async () => {
                                await axios.post(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/create-details-check?platformUUID=" + uuid + "&startDate=" + startDate.timestamp + "&endDate=" + endDate.timestamp , {
                                  discount: discount ? Number(discount) : 0,
                                }, {
                                  headers: {
                                    Authorization: getCookie("admin_auth"),
                                  },
                                }).then(async (response) => {
                                  setResultPrice(response.data.data);
                                }).catch((error) => {

                                  toast.error("Failed to check discount!");
                                  
                                  if (error.response) {
                                    if (error.response.status == 401) {
                                      navigate("/login");
                                    }
                                  }
                                });
                              }}
                            >
                              Cek Harga
                            </button>
                          </div>
                          {
                            resultPrice && (
                              <div className="flex flex-col text-sm mt-1">
                                <h1>Harga Awal: Rp {Number(resultPrice.original_price).toLocaleString("id-ID")}</h1>
                                <h1>Harga Setelah Diskon: Rp {Number(resultPrice.total_price).toLocaleString("id-ID")}</h1>
                              </div>
                            )
                          }
                        </div>
                        {
                          description && (
                            <div className="flex justify-end items-center">
                              <button
                                style={{ backgroundColor: currentColor }}
                                className="text-white w-36 mt-4 py-2 px-4 rounded-md text-sm"
                                onClick={async () => {
                                  setCreateLoading(true);

                                  const generateInvoice = async (invoiceUuid, htmlString) => {
                                    await axios.post(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/invoice/" + invoiceUuid, {
                                      html: htmlString
                                    }, {
                                      headers: {
                                        Authorization: getCookie("admin_auth"),
                                      },
                                    }).then(async (response) => {
                                      setInvoiceUrl(response.data.data.invoice);

                                      window.open(response.data.data.invoice, "_blank");

                                      setToggleCreate(false);
                                      fetchData(startDate.timestamp, endDate.timestamp);
                                      setToggleSuccess(true);
                                    }).catch((error) => {
  
                                      toast.error("Failed to create invoice!");
                                      
                                      if (error.response) {
                                        if (error.response.status == 401) {
                                          navigate("/login");
                                        }
                                      }
                                    });
                                  }

                                  const getUserCounter = async (invoiceUuid, invoiceData, csEmail, bankData) => {
                                    const userCounter = {};

                                    documentData.forEach(data => {
                                      const userUuid = data.SignerUuid[0];

                                      if (userCounter[userUuid]) {
                                        userCounter[userUuid]++;
                                      } else {
                                        userCounter[userUuid] = 1;
                                      }
                                    });

                                    const userCounterData = Object.keys(userCounter).map(uuid => ({
                                      name: documentData.find(data => data.SignerUuid[0] === uuid).SignerName[0],
                                      quantity: userCounter[uuid],
                                    }));

                                    const invoiceHtml = await InvoiceTemplate(invoiceData, csEmail, bankData, userCounterData);
                                    const htmlString = renderToString(invoiceHtml);

                                    await generateInvoice(invoiceUuid, htmlString);
                                  }

                                  const getBankData = async (invoiceUuid, invoiceData, csEmail) => {
                                    await axios.get(API_ROOT + "/primary/masterdata/bank-payment"
                                    ).then(async (response) => {
                                      await getUserCounter(invoiceUuid, invoiceData, csEmail, response.data.data);
                                    }).catch((error) => {
  
                                      toast.error("Failed to create invoice!");
                                      
                                      if (error.response) {
                                        if (error.response.status == 401) {
                                          navigate("/login");
                                        }
                                      }
                                    });
                                  }

                                  const getCsEmail = async (invoiceUuid, invoiceData) => {
                                    await axios.get(API_ROOT + "/primary/masterdata/cs-email"
                                    ).then(async (response) => {
                                      await getBankData(invoiceUuid, invoiceData, response.data.data.email);
                                    }).catch((error) => {
  
                                      toast.error("Failed to create invoice!");
                                      
                                      if (error.response) {
                                        if (error.response.status == 401) {
                                          navigate("/login");
                                        }
                                      }
                                    });
                                  }

                                  const getTransactionDetail = async (invoiceUuid) => {
                                    await axios.get(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/transaction/detail/" + invoiceUuid, {
                                      headers: {
                                        Authorization: getCookie("admin_auth"),
                                      },
                                    }).then(async (response) => {
                                      await getCsEmail(invoiceUuid, response.data.data);
                                    }).catch((error) => {
  
                                      toast.error("Failed to create invoice!");
                                      
                                      if (error.response) {
                                        if (error.response.status == 401) {
                                          navigate("/login");
                                        }
                                      }
                                    });
                                  }

                                  const createInvoice = async () => {
                                    await axios.post(API_ROOT + "/admin/user/integrated-platform/payment-pascabayar/create?platformUUID=" + uuid + "&startDate=" + startDate.timestamp + "&endDate=" + endDate.timestamp, {
                                      description: description,
                                      discount: discount ? Number(discount) : 0,
                                    }, {
                                      headers: {
                                        Authorization: getCookie("admin_auth"),
                                      },
                                    }).then(async (response) => {
                                      await getTransactionDetail(response.data.data);
                                    }).catch((error) => {
  
                                      toast.error("Failed to create invoice!");
                                      
                                      if (error.response) {
                                        if (error.response.status == 401) {
                                          navigate("/login");
                                        }
                                      }
                                    });
                                  }

                                  await createInvoice();

                                  setCreateLoading(false);
                                }}
                              >
                                {!createLoading ? "Create Invoice" : "Loading..."}
                              </button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
        {
          toggleSuccess && (
            <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full overflow-y-auto py-40 px-[100px] bg-slate-800/50">
              <div className="bg-white flex flex-col items-center gap-6 w-full max-w-[300px] h-fit rounded-xl p-8">
                <div>
                  <FiCheckCircle className="text-green-500 w-16 h-16" />
                </div>
                <h1 className="text-center">Success to Create Invoice</h1>
                <div className="flex justify-between gap-4">
                  <button
                    className="bg-gray-400 text-white py-2 px-4 rounded-md text-sm"
                    onClick={() => {
                      setDescription(null);
                      setInvoiceUrl(null);
                      setDiscount(null);
                      setResultPrice(null);
                      setToggleSuccess(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    style={{ backgroundColor: currentColor }}
                    className="text-white py-2 px-4 rounded-md text-sm"
                    onClick={() => window.open(invoiceUrl, "_blank")}
                  >
                    Open Invoice
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default PascabayarPayment;