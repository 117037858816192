import { MdQuestionMark } from "react-icons/md";

const DeleteValidation = ({ title, falseHandler, trueHandler }) => {
  return (
    <div 
      className="fixed z-[999999999999] top-0 left-0 flex justify-center items-center w-full min-h-screen h-full overflow-y-auto px-[100px] bg-slate-800/50"
    >
      <div className="flex flex-col gap-6 items-center bg-white p-8 rounded-lg h-fit">
        <div className={`w-16 h-16 flex justify-center items-center rounded-full border-4 border-amber-500`}>
          <div className={`[&>*]:w-10 [&>*]:h-10 text-amber-500`}>
            <MdQuestionMark />
          </div>
        </div>
        <h4 className="text-base font-medium">{title}</h4>
        <div className="flex justify-center gap-4">
          <button 
            className="rounded-lg px-4 py-2 text-gray-800 select-none w-fit bg-gray-300 hover:bg-gray-400"
            onClick={falseHandler}
          >
            Cancel
          </button>
          <button 
            className="rounded-lg px-4 py-2 text-white select-none w-fit bg-red-500 hover:bg-red-600"
            onClick={trueHandler}
          >
            Yes, delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteValidation;