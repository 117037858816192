import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";

import { API_ROOT } from "../../../config";
import { PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

import getCurrentDate from "../../../handlers/getCurrentDate";
import getFirstOfMonth from "../../../handlers/getFirstOfMonth";
import convertToTimestamp from "../../../handlers/convertToTimestamp";

const Details = () => {

  const { uuid } = useParams();
  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const [startDate, setStartDate] = useState(getFirstOfMonth());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [data, setData] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const getCounterPlatform = async (startDate, endDate) => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/recap/pascabayar-by-date?platformUUID=" + uuid + "&startDate=" + startDate + "&endDate=" + endDate, {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setInvalid(false);
      
      const mainData = response.data.data;

      setData(mainData);
    }).catch((error) => {
    
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.err && error.response.data.err.data) {
          if (error.response.data.err.data.code == -1 || error.response.data.err.data.code == -2) {
            setInvalid(true);
          } else {
            toast.error("Failed to open data");
          }
        } else {
          toast.error("Failed to open data");
        }
      } else {
        toast.error("Failed to open data");
      }
    });
  }

  useEffect(() => {
    if (startDate && endDate) {
      getCounterPlatform(startDate.timestamp, endDate.timestamp);
    }
  }, [startDate, endDate]);

  return !data ? (<PageLoading />) : (
    <div className="flex flex-col gap-4 m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <div className="flex items-center gap-4">
        <button
          onClick={() => navigate("/user/recap-pascabayar")}
        >
          <IoMdArrowBack className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-medium">Recap Pascabayar Counter by Date</h1>
      </div>
      <hr />
      <div className="flex items-center gap-4">
        <div>Filter Tanggal</div>
        <div>-</div>
        <div className="flex items-center gap-2">
          <input 
            type="date"
            className="p-2 border rounded-md"
            style={{ borderColor: currentColor, outlineColor: currentColor }}
            value={startDate.date}
            onChange={(e) => {
              setStartDate({
                date: e.target.value,
                timestamp: convertToTimestamp(e.target.value, 0, 0, 0)
              })
            }}
          />
          <h2>s/d</h2>
          <input 
            type="date"
            className="p-2 border rounded-md"
            style={{ borderColor: currentColor, outlineColor: currentColor }}
            value={endDate.date}
            onChange={(e) => {
              setEndDate({
                date: e.target.value,
                timestamp: convertToTimestamp(e.target.value, 23, 59, 59)
              })
            }}
          />
        </div>
      </div>
      {
        invalid ? (
          <div className="text-center">Filter Tanggal Invalid</div>
        ) : (
          <div className="flex flex-col gap-1">
            <div className="flex">
              <div className="flex-none w-48 bg-gray-600 text-white pl-2 py-1 flex items-center">Platform Name</div>
              <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{data.platform_name}</div>
            </div>
            <div className="flex">
              <div className="flex-none w-48 bg-gray-600 text-white pl-2 py-1 flex items-center">Total</div>
              <div className="flex-auto px-2 py-1 border border-gray-600 box-border break-all">{data.counter}</div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Details;