import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useNavigate } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { useState, useEffect, useRef } from "react";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./balance-transaction.css";
import PutInvoice from "./PutInvoice";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

import formatDate from "../../../handlers/formatDate";

const BalanceTransaction = () => {

  const { currentColor } = useStateContext();

  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("pending");
  const [transactionList, setTransactionList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [paidList, setPaidList] = useState([]);
  const [doneList, setDoneList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [expiredList, setExpiredList] = useState([]);
  const [toggleDetail, setToggleDetail] = useState(false);
  const [detailInvoice, setDetailInvoice] = useState(null);
  const [rejectedReason, setRejectedReason] = useState("");
  const [toggleReject, setToggleReject] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const gridPendingRef = useRef(null);
  const gridPaidRef = useRef(null);
  const gridDoneRef = useRef(null);
  const gridRejectedRef = useRef(null);
  const gridExpiredRef = useRef(null);

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/user/balance-transaction/list?offset=1&limit=all&status=all", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const data = response.data.data;

      if (data.length == 0) {
        setPageLoading(false);
      }

      setTransactionList(() => data.map((item, index) => (
        {
          Uuid: item.uuid,
          NoInvoice: item.invoice,
          Invoice: item.uuid,
          Amount: item.amount,
          Price: item.price,
          Status: item.status,
          OrderedDate: formatDate(item.created_at),
          DueDate: formatDate(item.due_date),
          PaidDate: item.paid_at ? formatDate(item.paid_at) : null,
          UpdatedDate: item.updated_at ? formatDate(item.updated_at) : null,
          Username: item.user.username,
          Email: item.user.email,
          PhoneNumber: item.user.phone_number,
        }
      )));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const putPdfInvoice = async (detail, status) => {
    const { price_per_balance, bank_payment, cs_email } = await getInvoiceMasterdata();

    const InvoicePdf = await PutInvoice(detail, bank_payment, cs_email, price_per_balance, status);

    const pdfString = renderToString(InvoicePdf);

    await axios.put(API_ROOT + "/admin/user/balance-transaction/invoice/" + detail.uuid, {
      html: pdfString,
    },{
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      window.open(response.data.data.invoice);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });
  }

  const getInvoiceMasterdata = async () => {

    let masterdata = {
      price_per_balance: null,
      bank_payment: null,
      cs_email: null,
    }

    await axios.get(API_ROOT + "/primary/masterdata/price-per-balance", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      masterdata.price_per_balance = response.data.data.price_per_balance;
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    await axios.get(API_ROOT + "/primary/masterdata/bank-payment", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      masterdata.bank_payment = response.data.data;
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    await axios.get(API_ROOT + "/primary/masterdata/cs-email", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      masterdata.cs_email = response.data.data.email;
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    });

    return masterdata;
  }

  useEffect(() => {
    setPageLoading(true);
    setTransactionList([]);
    setToggleDetail(false);
    setDetailInvoice(null);
    setRejectedReason("");
    setToggleReject(false);
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (transactionList.length !== 0) {
      setPageLoading(false);
      setPendingList(() => transactionList.filter((item) => item.Status === "pending").map((item, index) => ({ ...item, No: index+1})));
      setPaidList(() => transactionList.filter((item) => item.Status === "paid").map((item, index) => ({ ...item, No: index+1})));
      setDoneList(() => transactionList.filter((item) => item.Status === "done").map((item, index) => ({ ...item, No: index+1})));
      setRejectedList(() => transactionList.filter((item) => item.Status === "rejected").map((item, index) => ({ ...item, No: index+1})));
      setExpiredList(() => transactionList.filter((item) => item.Status === "expired").map((item, index) => ({ ...item, No: index+1})));
    }
  }, [transactionList]);

  // const invoiceTemplate = (props) => {
  //   return (
  //     <div className="e-invoiceParent">
  //       <a href={API_ROOT + "/primary/my/balance/topup/invoice/" + props.Invoice + "?token=" + getCookie("admin_auth")} target="_blank" className="e-invoice">
  //         <HiDocument className="e-invoiceIcon" />
  //         <div>Open</div>
  //       </a>
  //     </div>
  //   );
  // }

  const priceTemplate = (props) => {
    return (<>{"Rp " + props.Price.toLocaleString("id-ID")}</>);
  }

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
    // if (args.column?.field === "Status") {
    //   args.cell?.classList.add("e-status");
    //   if (args.data["Status"] === "unpaid") {
    //     args.cell?.classList.add("e-statusPending");
    //   } else if (args.data["Status"] === "paid") {
    //     args.cell?.classList.add("e-statusPaid");
    //   } else if (args.data["Status"] === "done") {
    //     args.cell?.classList.add("e-statusDone");
    //   } else if (args.data["Status"] === "rejected") {
    //     args.cell?.classList.add("e-statusRejected");
    //   } else if (args.data["Status"] === "expired") {
    //     args.cell?.classList.add("e-statusExpired");
    //   }
    // }
  }

  const dataBound = () => {
    if (gridPendingRef.current) {
      gridPendingRef.current.autoFitColumns(["NoInvoice", "OrderedDate", "DueDate"]);
    }
    if (gridPaidRef.current) {
      gridPaidRef.current.autoFitColumns(["NoInvoice", "OrderedDate", "PaidDate"]);
    }
    if (gridDoneRef.current) {
      gridDoneRef.current.autoFitColumns(["NoInvoice", "OrderedDate", "UpdatedDate"]);
    }
    if (gridRejectedRef.current) {
      gridRejectedRef.current.autoFitColumns(["NoInvoice", "OrderedDate", "UpdatedDate"]);
    }
    if (gridExpiredRef.current) {
      gridExpiredRef.current.autoFitColumns(["NoInvoice", "OrderedDate", "DueDate"]);
    }
  }

  const rowSelected = (ref) => {
    if (ref.current) {

      // if (ref.current.selectionModule.focus.prevIndexes.cellIndex == 2) return;

      setToggleDetail(true);

      const selectedRecords = ref.current.getSelectedRecords()[0];
      const uuid = selectedRecords.Uuid;

      axios.get(API_ROOT + "/admin/user/balance-transaction/details/" + uuid, {
        headers: {
          Authorization: getCookie("admin_auth"),
        },
      }).then((response) => {
        const mainData = response.data.data;

        setDetailInvoice(mainData);
        window.scrollTo({ top: 0, left: 0});
  
      }).catch((error) => {

        setToggleDetail(false);

        toast.error("Failed to open data");
        
        if (error.response) {
          if (error.response.status == 401) {
            navigate("/login");
          }
        }
      });
    } else {
      setToggleDetail(false);

      toast.error("Failed to open data");
    }
  }

  const toolbarClick = (args) => {
    if (gridRef.current && args.item.id.includes("excelexport")) {
      const excelExportProperties = {
        fileName: "Paperless - Digitalisasi Untuk Negeri Balance Transaction Data.xlsx"
      };
      gridRef.current.excelExport(excelExportProperties);
    }
  }

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Balance Transaction Data" sub="Click data to give response" />
      <div
        className="flex items-center flex-wrap gap-8 mb-4 pb-4 border-b-2"
        style={{ 
          borderColor: currentColor,
          }}
      >
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "pending" ? currentColor : "white",
            color: status === "pending" ? "white" : currentColor,
            }}
          onClick={() => setStatus("pending")}
        >
          Unpaid
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "paid" ? currentColor : "white",
            color: status === "paid" ? "white" : currentColor,
            }}
          onClick={() => setStatus("paid")}
        >
          Paid
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "done" ? currentColor : "white",
            color: status === "done" ? "white" : currentColor,
            }}
          onClick={() => setStatus("done")}
        >
          Approved
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "rejected" ? currentColor : "white",
            color: status === "rejected" ? "white" : currentColor,
            }}
          onClick={() => setStatus("rejected")}
        >
          Rejected
        </button>
        <button
          className="py-2 px-3 rounded-md flex justify-center font-medium"
          style={{ 
            backgroundColor: status === "expired" ? currentColor : "white",
            color: status === "expired" ? "white" : currentColor,
            }}
          onClick={() => setStatus("expired")}
        >
          Expired
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="w-fit cursor-pointer">
          {
            status === "pending" && (
              <GridComponent
                dataSource={pendingList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search", "ExcelExport"]}
                selectionSettings={{ type: "Single", mode: "Row" }}
                rowSelected={() => rowSelected(gridPendingRef)}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                toolbarClick={toolbarClick}
                dataBound={dataBound}
                ref={gridPendingRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  {/* <ColumnDirective field="Invoice" headerText="Invoice" textAlign="Center" template={invoiceTemplate} /> */}
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Price" headerText="Price" textAlign="Left" width={120} template={priceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" />
                  <ColumnDirective field="DueDate" headerText="Due Date" textAlign="Center" />
                  <ColumnDirective field="Username" headerText="Username" textAlign="Left" width={150} />
                  <ColumnDirective field="Email" headerText="Email" textAlign="Left" width={200} />
                  <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" width={150} />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
              </GridComponent>
            )
          }
          {
            status === "paid" && (
              <GridComponent
                dataSource={paidList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search", "ExcelExport"]}
                selectionSettings={{ type: "Single", mode: "Row" }}
                rowSelected={() => rowSelected(gridPaidRef)}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                toolbarClick={toolbarClick}
                dataBound={dataBound}
                ref={gridPaidRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  {/* <ColumnDirective field="Invoice" headerText="Invoice" textAlign="Center" template={invoiceTemplate} /> */}
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Price" headerText="Price" textAlign="Left" width={120} template={priceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" />
                  <ColumnDirective field="PaidDate" headerText="Paid Date" textAlign="Center" />
                  <ColumnDirective field="Username" headerText="Username" textAlign="Left" width={150} />
                  <ColumnDirective field="Email" headerText="Email" textAlign="Left" width={200} />
                  <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" width={150} />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
              </GridComponent>
            )
          }
          {
            status === "done" && (
              <GridComponent
                dataSource={doneList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search", "ExcelExport"]}
                selectionSettings={{ type: "Single", mode: "Row" }}
                rowSelected={() => rowSelected(gridDoneRef)}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                toolbarClick={toolbarClick}
                dataBound={dataBound}
                ref={gridDoneRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  {/* <ColumnDirective field="Invoice" headerText="Invoice" textAlign="Center" template={invoiceTemplate} /> */}
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Price" headerText="Price" textAlign="Left" width={120} template={priceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" />
                  <ColumnDirective field="UpdatedDate" headerText="Approved Date" textAlign="Center" />
                  <ColumnDirective field="Username" headerText="Username" textAlign="Left" width={150} />
                  <ColumnDirective field="Email" headerText="Email" textAlign="Left" width={200} />
                  <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" width={150} />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
              </GridComponent>
            )
          }
          {
            status === "rejected" && (
              <GridComponent
                dataSource={rejectedList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search", "ExcelExport"]}
                selectionSettings={{ type: "Single", mode: "Row" }}
                rowSelected={() => rowSelected(gridRejectedRef)}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                toolbarClick={toolbarClick}
                dataBound={dataBound}
                ref={gridRejectedRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  {/* <ColumnDirective field="Invoice" headerText="Invoice" textAlign="Center" template={invoiceTemplate} /> */}
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Price" headerText="Price" textAlign="Left" width={120} template={priceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" />
                  <ColumnDirective field="UpdatedDate" headerText="Rejected Date" textAlign="Center" />
                  <ColumnDirective field="Username" headerText="Username" textAlign="Left" width={150} />
                  <ColumnDirective field="Email" headerText="Email" textAlign="Left" width={200} />
                  <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" width={150} />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
              </GridComponent>
            )
          }
          {
            status === "expired" && (
              <GridComponent
                dataSource={expiredList}
                width="auto"
                allowPaging
                allowSorting
                allowTextWrap={true}
                pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                textWrapSettings={{ wrapMode: 'Content' }}
                toolbar={["Search", "ExcelExport"]}
                selectionSettings={{ type: "Single", mode: "Row" }}
                rowSelected={() => rowSelected(gridExpiredRef)}
                queryCellInfo={queryCellInfo}
                allowExcelExport={true}
                toolbarClick={toolbarClick}
                dataBound={dataBound}
                ref={gridExpiredRef}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                  <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                  <ColumnDirective field="NoInvoice" headerText="No Invoice" textAlign="Center" />
                  {/* <ColumnDirective field="Invoice" headerText="Invoice" textAlign="Center" template={invoiceTemplate} /> */}
                  <ColumnDirective field="Amount" headerText="Amount" textAlign="Center" width={90} />
                  <ColumnDirective field="Price" headerText="Price" textAlign="Left" width={120} template={priceTemplate} />
                  <ColumnDirective field="OrderedDate" headerText="Ordered Date" textAlign="Center" />
                  <ColumnDirective field="DueDate" headerText="Due Date" textAlign="Center" />
                  <ColumnDirective field="Username" headerText="Username" textAlign="Left" width={150} />
                  <ColumnDirective field="Email" headerText="Email" textAlign="Left" width={200} />
                  <ColumnDirective field="PhoneNumber" headerText="Phone Number" textAlign="Left" width={150} />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
              </GridComponent>
            )
          }
        </div>
      </div>
      {
        toggleDetail && (
          <div className="overflow-auto absolute z-[999999999999] top-0 left-0 flex justify-center w-full min-h-screen h-full py-40 px-[100px] bg-slate-800/50">
            {
              !detailInvoice ? (
                <div className="bg-white w-full max-w-[1000px] h-fit rounded-xl p-8">
                  Loading...
                </div>
              ) : (
                <div className="bg-white w-full max-w-[1000px] h-fit rounded-xl p-8">
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl font-medium">Invoice Detail</h1>
                    <div
                      className="cursor-pointer p-2"
                      onClick={() => {
                        setToggleDetail(false);
                        setDetailInvoice(null);
                        setRejectedReason("");
                        setToggleReject(false);
                      }}
                    >
                      &times;
                    </div>
                  </div>
                  <hr />
                  <div className="flex flex-col gap-1 mt-2">
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">No Invoice</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.invoice}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Status</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.status === "pending" ? "unpaid" : detailInvoice.status === "done" ? "approved" : detailInvoice.status}</div>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Amount</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.amount}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Price</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{"Rp " + detailInvoice.price.toLocaleString("id-ID")}</div>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Ordered Date</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{formatDate(detailInvoice.created_at)}</div>
                      </div>
                      {
                        (detailInvoice.status === "pending" || detailInvoice.status === "expired") && (
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Due Date</div>
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{formatDate(detailInvoice.due_date)}</div>
                          </div>
                        )
                      }
                      {
                        (detailInvoice.status === "paid" || detailInvoice.status === "done"  || detailInvoice.status === "rejected") && (
                          <div className="basis-1/2 flex">
                            {
                              detailInvoice.status === "paid" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Paid Date</div>
                              )
                            }
                            {
                              detailInvoice.status === "done" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Approved Date</div>
                              )
                            }
                            {
                              detailInvoice.status === "rejected" && (
                                <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Rejected Date</div>
                              )
                            }
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{formatDate(detailInvoice.updated_at)}</div>
                          </div>
                        )
                      }
                    </div>
                    {
                      (detailInvoice.paid_proof && detailInvoice.paid_at) && (
                        <div className="flex gap-1">
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Paid Proof</div>
                            <div className="flex-1 px-2 py-2 border border-gray-600 box-border w-[65px]">
                              <button 
                                className="block w-fit text-blue-500 border border-blue-500 py-1 px-2 text-sm"
                                onClick={() => {
                                  var image = new Image();
                                  image.src = "data:image/*;base64," + detailInvoice.paid_proof;
                                  image.style.height = "100%";
                                  image.style.width = "auto";

                                  var win = window.open("");
                                  win.document.write(image.outerHTML);
                                }}
                              >
                                Open Image
                              </button>
                            </div>
                          </div>
                          <div className="basis-1/2 flex">
                            <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Payment Date</div>
                            <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px] flex items-center">{formatDate(detailInvoice.paid_at)}</div>
                          </div>
                        </div>
                      )
                    }
                    <div className="flex gap-1">
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Username</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.user.username}</div>
                      </div>
                      <div className="basis-1/2 flex">
                        <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Phone Number</div>
                        <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.user.phone_number}</div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-40 bg-gray-600 text-white pl-2 py-1 flex items-center flex-none">Email</div>
                      <div className="flex-auto px-2 py-1 border border-gray-600 box-border w-[65px]">{detailInvoice.user.email}</div>
                    </div>
                  </div>
                  {
                    (detailInvoice.status === "paid" && !toggleReject) && (
                      <div className="flex justify-center items-center gap-4 mt-4">
                        <button
                          disabled={submitLoading}
                          className="text-white w-32 py-2 px-4 rounded-md text-sm bg-green-500 hover:bg-green-600"
                          onClick={async () => {

                            setSubmitLoading(true);

                            await axios.put(API_ROOT + "/admin/user/balance-transaction/accept/" + detailInvoice.uuid, {},{
                              headers: {
                                Authorization: getCookie("admin_auth"),
                              },
                            }).then(async () => {
                              await putPdfInvoice(detailInvoice, "done");
                              setToggleDetail(false);
                              setDetailInvoice(null);
                              toast.success("Invoice Approved!", {
                                autoClose: 500,
                                onClose: () => setRefresh((prev) => prev + 1),
                              });
                            }).catch((error) => {

                              toast.error("Failed to approve invoice");
                              
                              if (error.response) {
                                if (error.response.status == 401) {
                                  navigate("/login");
                                }
                              }
                            });

                            setSubmitLoading(false);
                          }}
                        >
                          {!submitLoading ? "Approve" : "Loading..."}
                        </button>
                        <button
                          disabled={submitLoading}
                          className="text-white w-32 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                          onClick={() => setToggleReject(true)}
                        >
                          Reject
                        </button>
                      </div>
                    )
                  }
                  {
                    (detailInvoice.status === "rejected" || toggleReject) && (
                      <div className="flex flex-col gap-2 mt-4">
                        <h1>Rejected Reason</h1>
                        <textarea  
                          rows="5"
                          placeholder="rejected reason..."
                          className="w-full border border-gray-400 px-4 py-2 text-sm rounded-md"
                          readOnly={detailInvoice.status === "rejected" ? true : false}
                          value={detailInvoice.status === "rejected" ? detailInvoice.rejected_reason : rejectedReason}
                          onChange={(e) => setRejectedReason(e.target.value)}
                        ></textarea>
                        {
                          detailInvoice.status !== "rejected" && (
                            <div className="flex justify-center items-center gap-4 mt-4">
                              <button
                                disabled={submitLoading}
                                className="text-white w-32 py-2 px-4 rounded-md text-sm bg-slate-500 hover:bg-slate-600"
                                onClick={() => {
                                  setToggleReject(false);
                                  setRejectedReason("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={submitLoading || !rejectedReason}
                                className="text-white w-32 py-2 px-4 rounded-md text-sm bg-red-500 hover:bg-red-600"
                                onClick={async () => {

                                  if (!rejectedReason) return;

                                  setSubmitLoading(true);

                                  await axios.put(API_ROOT + "/admin/user/balance-transaction/reject/" + detailInvoice.uuid, {
                                    reason: rejectedReason
                                  },{
                                    headers: {
                                      Authorization: getCookie("admin_auth"),
                                    },
                                  }).then(async () => {
                                    await putPdfInvoice(detailInvoice, "rejected");
                                    setDetailInvoice(null);
                                    setToggleDetail(false);
                                    toast.success("Invoice Rejected!", {
                                      autoClose: 500,
                                      onClose: () => setRefresh((prev) => prev + 1),
                                    });
                                  }).catch((error) => {
          
                                    toast.error("Failed to reject invoice");
                                    
                                    if (error.response) {
                                      if (error.response.status == 401) {
                                        navigate("/login");
                                      }
                                    }
                                  });

                                  setSubmitLoading(false);
                                }}
                              >
                                {!submitLoading ? "Reject" : "Loading..."}
                              </button>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};
export default BalanceTransaction;