import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import "./pascabayarPayment.css";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";

import getCurrentDate from "../../../handlers/getCurrentDate";
import getFirstOfMonth from "../../../handlers/getFirstOfMonth";
import convertToTimestamp from "../../../handlers/convertToTimestamp";

const PascabayarPayment = () => {

  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const abortController = new AbortController();

  const [pageLoading, setPageLoading] = useState(true);
  const [startDate, setStartDate] = useState(getFirstOfMonth());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [detailTrigger, setDetailTrigger] = useState(0);
  const [invalid, setInvalid] = useState(false);
  const [counterData, setCounterData] = useState([]);
  const gridRef = useRef(null);

  const fetchData = async (startDate, endDate) => {
    await axios.get(API_ROOT + "/admin/user/integrated-platform/recap/pascabayar?offset=1&limit=all&is_penagihan=0&startDate=" + startDate + "&endDate=" + endDate, {
      signal: abortController.signal,
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setInvalid(false);

      const counterList = response.data.data;

      setCounterData(() => counterList.map((item, index) => (
        {
          Uuid: item.uuid,
          No: index+1,
          PlatformName: item.platform_name,
          Total: item.counter,
        }
      )));
    }).catch((error) => {
      if (error.code == "ERR_CANCELED") return;
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.err && error.response.data.err.data) {
          if (error.response.data.err.data.code == -3 || error.response.data.err.data.code == -4) {
            setInvalid(true);
          } else {
            toast.error("Failed to open data");
          }
        } else {
          toast.error("Failed to open data");
        }
      } else {
        toast.error("Failed to open data");
      }
    }).finally(() => setPageLoading(false));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        fetchData(startDate.timestamp, endDate.timestamp);
      }
    }, 500);

    return () => {
      abortController.abort();
      clearTimeout(timeout);
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (detailTrigger !== 0) {
      navigate(detailTrigger + "?startDate=" + startDate.timestamp + "&endDate=" + endDate.timestamp);
    }
  }, [detailTrigger]);

  const actionTemplate = (props) => {
    return (
      <div className="e-actionParent">
        <button onClick={() => setDetailTrigger(props.Uuid)} className="e-action">
          <BiMessageSquareDetail className="e-actionIcon" />
          <div>Detail</div>
        </button>
      </div>
    );
  };

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  };

  const dataBound = () => {
    if (gridRef.current) {
      gridRef.current.autoFitColumns(["No", "PlatformName", "Total", "Uuid"]);
    }
  };

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="Pascabayar Payment" />
      <div className="flex flex-col gap-4">
        <hr />
        <div className="flex items-center gap-4">
          <div>Filter Tanggal</div>
          <div>-</div>
          <div className="flex items-center gap-2">
            <input 
              type="date"
              className="p-2 border rounded-md"
              style={{ borderColor: currentColor, outlineColor: currentColor }}
              value={startDate.date}
              onChange={(e) => {
                setStartDate({
                  date: e.target.value,
                  timestamp: convertToTimestamp(e.target.value, 0, 0, 0)
                })
              }}
            />
            <h2>s/d</h2>
            <input 
              type="date"
              className="p-2 border rounded-md"
              style={{ borderColor: currentColor, outlineColor: currentColor }}
              value={endDate.date}
              onChange={(e) => {
                setEndDate({
                  date: e.target.value,
                  timestamp: convertToTimestamp(e.target.value, 23, 59, 59)
                })
              }}
            />
          </div>
        </div>
        {
          invalid ? (
            <div className="text-center mt-4">Filter Tanggal Invalid</div>
          ) : (
            <div className="overflow-x-auto">
              <div className="w-fit">
                <GridComponent
                  dataSource={counterData}
                  width="auto"
                  allowPaging
                  allowSorting
                  allowTextWrap={true}
                  pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
                  textWrapSettings={{ wrapMode: 'Content' }}
                  toolbar={["Search"]}
                  queryCellInfo={queryCellInfo}
                  allowExcelExport={true}
                  dataBound={dataBound}
                  ref={gridRef}
                >
                  <ColumnsDirective>
                    <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} />
                    <ColumnDirective field="PlatformName" headerText="Platform Name" textAlign="Left" />
                    <ColumnDirective field="Total" headerText="Total" textAlign="Center" />
                    <ColumnDirective field="Uuid" headerText="Action" textAlign="Center" isPrimaryKey={true} template={actionTemplate} />
                    <ColumnDirective field="Blank" headerText="" textAlign="Center" />
                  </ColumnsDirective>
                  <Inject services={[Search, Toolbar, Page, Sort, Resize]} />
                </GridComponent>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default PascabayarPayment;